@font-face {
    font-family: 'Verdana-Bold';
    src: url('fonts/Verdana-Bold.eot');
    src: url('fonts/Verdana-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/Verdana-Bold.woff') format('woff'),
        url('fonts/Verdana-Bold.ttf') format('truetype'),
        url('fonts/Verdana-Bold.svg#Verdana') format('svg');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'Helvetica-preview';
    src: url('/fonts/Helvetica.ttf');
}

@font-face {
    font-family: 'Helvetica-bold-preview';
    src: url('/fonts/Helvetica-Bold.ttf');
}

@font-face {
    font-family: 'Helvetica-light-preview';
    src: url('/fonts/Helvetica-Light.ttf');
}

html{
    width: 100%;
    background-color: #F2F2F2;
}
body{
    padding:0;
    margin:0;
    font-family: Helvetica, sans-serif;
    position: initial !important;
    top: initial !important;
    background-color: #F2F2F2;
}

ul[dnd-list],
ul[dnd-list] > li {
  position: relative;
}

.md-virtual-repeat-scroller,
md-select-menu md-content {
    /*overflow-y: hidden;*/
    overflow-y: auto;
}
.md-virtual-repeat-scroller:hover,
md-select-menu md-content:hover {
    overflow-y: auto;
}

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop to it once it's empty
 */
ul[dnd-list] {
  min-height: 42px;
  padding-left: 0px;
}

/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
ul[dnd-list] .dndDraggingSource {
  display: none;
}

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
ul[dnd-list] .dndPlaceholder {
  display: block;
  background-color: #FFF;
  padding: 10px 0px;
  min-height: 42px;
}

/**
 * The dnd-lists's child elements currently MUST have
 * position: relative. Otherwise we can not determine
 * whether the mouse pointer is in the upper or lower
 * half of the element we are dragging over. In other
 * browsers we can use event.offsetY for this.
 */
ul[dnd-list] li {
  background-color: #fff;
  /*border: 1px solid #ddd;*/
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: block;
  margin-bottom: -1px;

  /* Disable text selection if item is not draggable */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

ul[dnd-list] li dnd-nodrag {
  display: block;
  padding: 10px 0px;
}

/**
 * Gender-specifc background
 */
ul[dnd-list] li.dndListBackground {
  background-color: #FFF;
}

ul[dnd-list] input.dndListBackground {
  background-color: #FFF;
  color: #000;
  padding: 5px;
  border: 2px solid rgb(0, 0, 0, 0.5);
  border-radius: 8px;
}
/**
 * Handle positioning
 */
.handle {
  cursor: move;
  position: absolute;
  top: 16px;
  display: none;
}

ul[dnd-list] li.dndListBackground:hover .handle {
    display: block;
}

.removeDndListItem {
    height: 22px;
    width: 22px; 
    margin-left: 10px; 
    margin-top: 5px; 
    cursor: pointer;
    display: none;
}

ul[dnd-list] li.dndListBackground:hover .removeDndListItem {
    display: block;
}

.name {
  margin-left: 20px;
}

.landingContainer {
    width: 640px;
    padding-left: 50px;
}

.ap-otp-input{
    border: 3px solid #ebebeb;
    border-radius: 12px;
    height: 32px;
    width: 30px;
    margin: 4px;
    text-align: center;
    font-size: 22px;
    text-transform: uppercase;
}

#publicUserId3{
    margin-right: 20px;
}


.ap-otp-input:focus{
    outline: none !important;
    border: 3px solid #00AEEF;
    transition: 0.12s ease-in;
}

.md-input[disabled] {
    color: rgba(0,0,0,0.38) !important
}

md-progress-circular svg path { stroke: white; }

.blueIndicator md-progress-circular svg path { stroke: #00AEEF; }

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }

#notesButton:focus {outline:none !important;}

#experiencesButton:focus {outline:none !important;}

#insertDateButton:focus {outline:none !important;}

#textArea1 {border: none;}

#textArea1:focus {outline:none !important;}

#textArea2 {border: none;}

#textArea2:focus {outline:none !important;}
.scrollToTopImg:focus  {
    outline:none !important;
}
.moreInfoText:focus  {
    outline:none !important;
}

.redbold {
    color: red;
}

.newPreregAltertWindowList {
    list-style-position: inside;
    padding-left: 20px;
}

.subscriptionLabel2 {
    width: 180px;
}

.fadeout_text1 {
    max-height: 100px;
    position: relative;
    overflow: hidden;
  }

  .fadeout_text2 {
    max-height: 110px;
    position: relative;
    overflow: hidden;
  }

  .fadeout_text3 {
    max-height: 110px;
    position: relative;
    overflow: hidden;
  }

  .fadeout_text4 {
    max-height: 130px;
    position: relative;
    overflow: hidden;
  }

  .fadeout_text5 {
    max-height: 120px;
    position: relative;
    overflow: hidden;
  }
  
  .read-more { 
    position: absolute; 
    bottom: 0; 
    left: 0;
    width: 100%; 
    text-align: center; 
    margin: 0; padding: 50px 0; 
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), white);
  }

  .read-more2 { 
    position: absolute; 
    bottom: 0; 
    left: 0;
    width: 100%; 
    text-align: center; 
    margin: 0;
  }

  .btn-read-more {
    background-color: #00AEEF;
    width: 150px;
    text-decoration: none;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .75rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#searchbox {
    margin: 10px auto 0 auto;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
    border-radius: 22px;
    width: 550px;
    height: 40px;
}

#quickSearchText {
    border: none; 
    width: 450px; 
    height: 35px; 
    outline: none;
    margin-left: 20px;
}

#searchboxButton {
    width: 60px; 
    height: 40px;
    text-align: center;
    background-color: #FFF;
    border: none;
    background-color: transparent;
    padding: 0;
    outline: none;
}

#advancedQuickSearchBottom .advancedSearchButton {
    width: 220px; 
    /*margin: 5px auto; */
    display: block; 
    text-transform: none;
    height: 36px;
}

#searchboxSearchIcon {
    color: #00AEEF;
}

#searchButtonContainer {
    margin-left: 10px;
}

#portfolioQuickSearchButtonContainer {
    width: 200px;
    margin-top: 15px;
    margin-left: 65px;
}

#portfolioQuickSearchButtonContainer .quickSearchButton {
    width: 170px;
    display: block; 
    text-transform: none;
    margin: 0;
}

#portfolioQuickSearchButtonContainer .quickSearchButtonContainer {
    width: 400px;
    left: -80px;
}

#portfolioQuickSearchButtonContainer .quickSearchRow {
    display: flex;
    margin: 5px;

}

#portfolioQuickSearchButtonContainer .quickSearchText {
    margin-left: 20px;
    width: 250px;
}

#redeemTypeLabel .tooltip .yaltytiptext::after {
    right: 220px;
}

/*.md-radio-button-invalid #timeId md-radio-button {
    color: #ED7D31 !important;
}*/

.md-radio-button-invalid .md-off {
    border-color: #ED7D31 !important;
}

.partnerContactDate .md-errors-spacer { 
    display: none; 
}

.welcomeScreenImagesDiv{
    width: 720px;
    background-color: #e6f7fd; 
    border-radius: 10px; 
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
}

.loyaltyCardYaltyScreenImagesDiv{
    width: 430px;
    background-color: #e6f7fd; 
    border-radius: 10px;
    padding-left: 40px; 
    padding-bottom: 20px;
    margin: auto; 
}

.welcomeScreenImages2 {
    display: block;
}

.welcomeScreenCenterDiv{
    margin-top: 45px;
}

.welcomeScreenDownloadDiv1{
    display: flex;
}
.welcomeScreenDownloadDiv2{
    display: none;
}

/*md-input-container #partnerContactTime .md-errors-spacer {
    display: none;
}*/

/*.md-input-container label:not(.md-container-ignore).md-required:after {
    display: none;
}*/

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px white;
}

.redSpan span {
    color: red;
}

.redSpan {
    margin: 0;
}

#overlayunstamp .cropper-view-box, #overlayunstamp .cropper-face {
    border-radius: 50%;
    border: solid 1px #39f;
    box-sizing: border-box;
}
#overlayStamp .cropper-view-box, #overlayStamp .cropper-face{
    border-radius: 50%;
    border: solid 1px #39f;
    box-sizing: border-box;
}
.container{
    width: 100%;
    height: 100%;
    display:flex;
    justify-content:center;
    vertical-align: middle;
}
.container .content {
    width:1366px;
    margin-top: 52px;
}
.container .yalty_top .content{
    width:1366px;
    height: 100%;
    margin: auto;
}
.container .contentYaltyAdmin {
    width:100%;
    margin-top: 52px;
}
.container .yalty_top .contentYaltyAdmin{
    width:100%;
    height: 100%;
    margin: auto;
}
.container .main{
    width: calc(100% - 206px);
    float: left;
    height: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    overflow: hidden;
    overflow-y: auto;
    background-color: white;
}
.container .aside{
    width: 205px;
    height: 100%;
    float: left;
}
.container .asideYaltyAdmin{
    width: 205px;
    height: 100%;
    float: left;
}
.container .fa-bar{
    width: 30px;
    height: 35px;
    float: left;
    padding-left: 10px;
    padding-top: 17px;
    margin-right: -20px;
    outline: none;
    display: none;
}
.container .fa-barYaltyAdmin{
    width: 30px;
    height: 35px;
    float: left;
    padding-left: 10px;
    padding-top: 17px;
    margin-right: -20px;
    outline: none;
    display: none;
}
.container #openMenu,.container #closeMenu{
    display:none;
}
.container #openMenuYaltyAdmin,.container #closeMenuYaltyAdmin{
    display:none;
}
.container #userMail{
    display:none;
}
.container #sloganText{
    float: left;
    color:#404040;
}
.container #yalty-slogan{
    float: left;
    font-family:'Verdana-Bold';
    font-size:200%;
    color:white;
    margin-top:0px;
    margin-bottom:0px;
    margin-left:25px;
    margin-right:10px;
}
.container #yaltySlogan{
    float: left;
    font-family:'Verdana-Bold';
    font-size:150%;
    color:white;
    margin-top:10px;
    margin-bottom:0px;
    margin-left:25px;
    margin-right:10px;
}
.container #user-detail-style{
    margin-right:20px;
    color:#404040;
    max-width: 400px;
}
.container #timer{
    margin-top:10px;
    font-size:150%;
}
.container .optionsMenu{
    float:right;
    margin-right:50px;
}
.container img{
    vertical-align: middle;
}
.container .dropdown {
    position: relative;
    display: inline-block;
    min-width: 210px;
}
.container .dropdown-content {
    display: none;
    width: 100%;
    background-color: white;
    position: absolute;
    margin-top:47px;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 5;
    color: black;
    padding: 4px 6px;
}
.container .content-item{
    padding:5px;
    height: 35px;
}
.container .content-item p{
    padding: 5px 0;
}
.container .content-item .imageContainer {
    position: relative;
}
.container .content-item .imageContainer img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.container .optionsMenu p {
    margin: 0;
}
.container #userName{
    font-weight: bold;
    color:white;
    margin: 5px 0;
}

.freepackage{
    margin-top: -216px;
}
.freepackagemobile{
    margin-top: -248px;
}

th.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
}

th.rotate > div {
  transform: 
    /* Magic Numbers */
    translate(25px, 51px)
    /* 45 is really 360 - 45 */
    rotate(315deg);
  width: 30px;
}

th.rotated {
    white-space: nowrap;
    vertical-align: bottom;
}

th.rotated > div {
    transform: rotate(315deg);
    transform-origin: top;
    width: 30px;
    padding-bottom: 10px;
}

th.day {
    height: 70px;
}

th.week {
    height: 150px;
}

th.month {
    height: 90px;
}

th.year {
    height: 40px;
}

.userNumberStatsSquareContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 120px;
    max-width: 1400px;
}

.userNumberStatsSquare {
    display: flex;
    flex-direction: column;
    min-width: 170px;
    height: 75px;
    border-radius: 15px;
    align-items: center;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding: 5px 0;
}

.userNumberStatsSquare .number {
    font-size: 22px;
}

.userNumberStatsSquare .label {
    font-size: 14px;
}

#userNumberStatsNavButtonContainer .userNumberStatsNavButton {
    width: 250px;
    height: 40px;
    font-weight: bold;
    background-color: #DFDFDF;
    color: #000;
}

#userNumberStatsNavButtonContainer .userNumberStatsNavButtonActive, 
#userNumberStatsNavButtonContainer .userNumberStatsNavButton:hover {
    background-color: #00AEEF;
    color: #fff;
}

#userNumberStatsNavButtonContainer {
    max-width: 1400px;
    justify-content: space-evenly;
    height: 80px;
    align-items: center;
}

td .yaltytiptextright,
.tooltip .yaltytiptext,
.tooltip .yaltytiptext2,
.tooltip .yaltytiptext3,
.tooltip .yaltytiptextright,
.tooltip .yaltytiptextright2,
.tooltip .yaltytiptextleft,
.tooltip .yaltytiptextbottom,
.tooltip .yaltytiptextbottom2,
.tooltip .yaltytiptextbottom3,
.tooltip .yaltytiptextbottom4,
.tooltip2 .yaltytiptextbottom3,
.tooltip2 .yaltytiptextleft,
.tooltip .advancedQuickSearchBottom,
.quickSearchInfoIcon .quickSearchTextLeft {
    z-index: 6;
    background: #fff;
    letter-spacing: -0.05px;
    border-radius: 8px;
    box-shadow: 0 1px 1px 0 rgba(60,64,67,.08), 0 1px 3px 1px rgba(60,64,67,.16);
    visibility: hidden;
    padding: 10px;
    width: 300px;
    position: absolute;
}
.tooltip .yaltytiptext,
.tooltip .yaltytiptext2,
.tooltip .yaltytiptext3{
    bottom: 40px;
    right: -30px;
}
td .yaltytiptextright,
.tooltip .yaltytiptextright, 
.tooltip .yaltytiptextright2 {
    bottom: -30px;
    left: 30px;
}
.tooltip .yaltytiptextleft,
.tooltip2 .yaltytiptextleft,
.quickSearchInfoIcon .quickSearchTextLeft {
    bottom: -42px;
    right: 30px;
}
.tooltip .yaltytiptext p,
.tooltip .yaltytiptext3 p,
.tooltip .yaltytiptext2 p,
.tooltip .yaltytiptextright p,
.tooltip .yaltytiptextright2 p,
.tooltip .yaltytiptextleft p, 
.tooltip span.yaltytiptextbottom,
.tooltip span.yaltytiptextbottom2,
.tooltip span.yaltytiptextbottom3,
.tooltip span.yaltytiptextbottom4,
.tooltip2 span.yaltytiptextbottom3,
.tooltip2 .yaltytiptextleft,
.tooltip span.advancedQuickSearchBottom,
.quickSearchInfoIcon .quickSearchTextLeft p {
    color: #5c5f62;
    font-family: "Roboto",sans-serif;
    font-size:12px;
    font-style: normal;
    line-height: 16px;
    font-weight: 400;
    margin: 0;
}
.tooltip span.yaltytiptextbottom,
.tooltip span.yaltytiptextbottom2,
.tooltip span.yaltytiptextbottom3,
.tooltip span.yaltytiptextbottom4,
.tooltip2 span.yaltytiptextbottom3
 {
    margin-top: 10px;
    width: 500px;
    right: 0px;
}

.tooltip span.advancedQuickSearchBottom {
    margin-top: 35px;
    right: 0px;
}
.tooltip .yaltytiptext::after {
    background-color: #fff;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "\0000a0";
    display: block;
    height: 20px;
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 20px;
    position: absolute;
    bottom: -10px;
    right: 30px;
}
.tooltip .yaltytiptext2::after {
    background-color: #fff;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "\0000a0";
    display: block;
    height: 20px;
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 20px;
    position: absolute;
    bottom: -10px;
    right: 10px;
}
.tooltip .yaltytiptext3::after {
    background-color: #fff;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "\0000a0";
    display: block;
    height: 20px;
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 20px;
    position: absolute;
    bottom: -10px;
    right: 250px;
}
td .yaltytiptextright::after,
.tooltip .yaltytiptextright::after,
.tooltip .yaltytiptextright2::after {
    background-color: #fff;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "\0000a0";
    display: block;
    height: 15px;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 15px;
    position: absolute;
    left: -8px;
    top: 20px;
}

.tooltip .yaltytiptextleft::after,
.tooltip2 .yaltytiptextleft::after,
.quickSearchInfoIcon .quickSearchTextLeft::after {
    background-color: #fff;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "\0000a0";
    display: block;
    height: 15px;
    transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    width: 15px;
    position: absolute;
    right: -5px;
    top: 8px;
}
.tooltip .yaltytiptextbottom::after {
    background-color: #fff;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "\0000a0";
    display: block;
    height: 20px;
    transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    width: 20px;
    position: absolute;
    top: -10px;
    right: 25px;
}
.tooltip .yaltytiptextbottom2::after {
    background-color: #fff;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "\0000a0";
    display: block;
    height: 20px;
    transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    width: 20px;
    position: absolute;
    top: -10px;
    right: 460px;
}
.tooltip .yaltytiptextbottom3::after {
    background-color: #fff;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "\0000a0";
    display: block;
    height: 20px;
    transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    width: 20px;
    position: absolute;
    top: -10px;
    right: 10px;
}
.tooltip .yaltytiptextbottom4::after {
    background-color: #fff;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "\0000a0";
    display: block;
    height: 20px;
    transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    width: 20px;
    position: absolute;
    top: -10px;
    right: 260px;
}
.tooltip2 .yaltytiptextbottom3::after {
    background-color: #fff;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "\0000a0";
    display: block;
    height: 20px;
    transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    width: 20px;
    position: absolute;
    top: -10px;
    right: 10px;
}
td:hover .yaltytiptextright,
.tooltip:hover .yaltytiptext,
.tooltip:hover .yaltytiptext2,
.tooltip:hover .yaltytiptext3,
.tooltip:hover .yaltytiptextright,
.tooltip:hover .yaltytiptextright2,
.tooltip:hover .yaltytiptextleft,
.tooltip:hover .yaltytiptextbottom,
.tooltip:hover .yaltytiptextbottom2,
.tooltip:hover .yaltytiptextbottom3,
.tooltip:hover .yaltytiptextbottom4,
.tooltip2:hover .yaltytiptextbottom3,
.tooltip2:hover .yaltytiptextleft,
.tooltip:hover .advancedQuickSearchBottom,
.quickSearchInfoIcon:hover .quickSearchTextLeft {
    visibility: visible;
    opacity: 1;
}
.createRedeemButtonRow {
    float: right;
}
.createRedeemButton.tooltip:hover .yaltytiptextleft {
    transition-delay:1s;
}
.tooltip, .tooltip2,
[class*="tooltip-"] {
    position: relative;
    display: inline-block;
}
i.tooltip, i[class*="tooltip-"], i.quickSearchInfoIcon {
    margin-left:20px;
    margin: 25px 15px;
    font-size: 20px;
    color: #00AEEF;
}
i.tooltip2 {
    margin-left:20px;
    margin: 25px 15px;
    font-size: 20px;
}
.tooltip .tooltiptext {
    visibility: hidden;
    width: 450px;
    font-size:14px;
    font-family: Helvetica, sans-serif;
    background-color: #4E525C;
    color: #fff;
    font-weight: 100;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 3;
    top: -5px;
    right: 110%;
    opacity: 0;
}
.tooltip .tooltiptextbottom,
.tooltip .tooltiptextbottom2,
.tooltip .tooltiptextbottom3 {
    width: 350px;
    top: 60%;
    left: 50%; 
    margin-left: -175px;
    font-size:14px;
    font-family: Helvetica, sans-serif;
    background-color: #4E525C;
    color: #fff;
    font-weight: 100;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    cursor: default;
}
.tooltip .tooltiptextbottom a,
.tooltip .tooltiptextbottom2 a,
.tooltip .tooltiptextbottom3 a,
.tooltip .tooltiptexttop a,
[class*="tooltip-"] [class*="tooltiptexttop-"] a{
    font-weight: bold;
    color: #ED7D31;
    cursor: pointer;
    text-decoration: none;
}
.tooltip .tooltiptexttop,
[class*="tooltip-"] [class*="tooltiptexttop-"] {
    width: 350px;
    bottom: 120%;
    left: 50%; 
    margin-left: -175px;
    font-size:14px;
    font-family: Helvetica, sans-serif;
    background-color: #4E525C;
    color: #fff;
    font-weight: 100;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
}

.tooltip .tooltiptextleft,
[class*="tooltip-"] [class*="tooltiptextleft-"] {
    visibility: hidden;
    width: 350px;
    font-size:14px;
    font-family: Helvetica, sans-serif;
    background-color: #4E525C;
    color: #fff;
    font-weight: 100;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 3;
    top: -40%;
    left: 150%;
    opacity: 0;
}
.tooltip .tooltiptextright,
[class*="tooltip-"] [class*="tooltiptextright-"] {
    visibility: hidden;
    width: 350px;
    font-size:14px;
    font-family: Helvetica, sans-serif;
    background-color: #4E525C;
    color: #fff;
    font-weight: 100;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    top: -40%;
    left: 150%;
    opacity: 0;
}

#companyListTable tbody td {
    position: relative;
    z-index: 1;
  }

#companyListTable tbody td:hover {
    z-index: 2;
}

#companyListTable tbody td.portFolio:hover {
    color: #00AEEF;
    cursor: pointer;
}

#companyListTable tbody td.portFolio2:hover {
    cursor: pointer;
}

.iconInTdContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip .tooltiptextbottom::after,
.tooltip .tooltiptextbottom2::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #4E525C transparent;
    /*transition: opacity  0s linear 2s;*/
  }

.tooltip .tooltiptextbottom3::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -170px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #4E525C transparent;
}

.tooltip .tooltiptextleft::after,
[class*="tooltip-"] [class*="tooltiptextleft-"]::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #4E525C transparent transparent;
}
/*.tooltip .tooltiptextright::after,
[class*="tooltip-"] [class*="tooltiptextright-"]::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #4E525C transparent transparent;
    transform: rotate(180deg)
}*/
.tooltip .tooltiptexttop::after, 
[class*="tooltip-"] [class*="tooltiptexttop-"]::after{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: #4E525C transparent transparent transparent;
}
[class*="tooltip-"] [class*="tooltiptexttop-"]::after{
    transition: opacity  0s linear 2s;
}
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #4E525C;
}
.tooltip:hover .tooltiptext, 
.tooltip:hover .tooltiptexttop,
.tooltip:hover .tooltiptextleft,
[class*="tooltip-"]:hover [class*="tooltiptextleft-"] {
    visibility: visible;
    opacity: 1;
}
.tooltip:hover .tooltiptextright,
[class*="tooltip-"]:hover [class*="tooltiptextright-"] {
    visibility: visible;
    opacity: 1;
}
.tooltip:hover .tooltiptextbottom,
.tooltip:hover .tooltiptextbottom3,  
[class*="tooltip-"]:hover [class*="tooltiptexttop-"] {
    visibility: visible;
    opacity: 1;
}

.tooltip .yaltytiptextright .primary-button:hover ~.tooltiptextbottom2 {
    visibility: visible;
    opacity: 1;
}
  
/*[class*="tooltip-"]:hover .tooltiptexttop-field {
    transition-delay:2s;
}*/

.container .optionsMenu:hover .dropdown-content{
    display: block;
}
.container .dropdown-content .content-item:hover{
    background-color:#00AEEF!important;
    color:white;
    cursor: pointer;
}
.container .welcomeListContainer .warningContainer {
    background-color: #00AEEF;
    color: #FFF;
    padding: 5px 10px;
}
.container .welcomeListContainer .warningContainer h3 {
    color: #FFF;
    margin: 0 10px;
}
.container .welcomeMobileContainer img {
    width: 150px;
    height: 300px;
    margin: 20px auto;
}
.container .welcomeListContainer ul {
    padding-left: 20px;
}
.container .welcomeListContainer li {
    list-style-type: disc;
}
.container .welcomeListContainer ul li ul {
    padding-left: 40px;

}
.container .welcomeListContainer a {
    text-decoration: none;
    color: #00AEEF;
}

.container .subInfo a {
    text-decoration: none;
    color: #00AEEF;
}

.container .welcomeListContainer .faqContainer {
    padding-top: 40px;
    padding-bottom: 20px;
}
.container .welcomeListContainer .faqContainer li {
    margin: 20px 0px;
}
.container .redeemContainer {
    width: fit-content;
}
.container .redeemContainer .createRedeemButton {
    display: block;
    float: right;
    width: 40px;
    cursor: pointer;
    margin-top: 12px;
    margin-right: 15px;
    outline: none;
}
.container .redeemContainer .createRedeemButton img {
    height: 40px;
    outline: none;
}
.container .redeemContainer .createRedeemWindow {
    position: absolute;
    background-color:white;
    width:525px;
    height:550px;
    top:52%;
    left:50%;
    transform:translate(-50%,-50%);
    border-radius: 15px;
}
.container .redeemContainer .YLCSelectedProductWindow {
    position: absolute;
    background-color:white;
    width:525px;
    height:260px;
    top:52%;
    left:50%;
    transform:translate(-50%,-50%);
    border-radius: 8px;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.container #createRedeemArea.cropArea {
    width: 525px;
    top: 50%;
}
.container .redeemStatusPanel {
    width: 600px;
    padding: 5px 10px;
    box-sizing: border-box;
}
.container .redeemStatusPanel img {
    margin: auto 10px auto 0;
}
.container .createRedeemFormMiddleElement {
    margin: auto;
    margin-bottom: 30px;
}
.container .createRedeemFormLargeElement {
    width: 75%;
    margin: auto;
}
.container li {
    list-style-type: none;
    width:100%;
    margin: 3px 0px;
    padding-bottom: 0;
}
.container .cashingInfo{
    padding-right:15px;
    padding-bottom:20px;
}
.container .yalty-sidebar ul{
    -webkit-margin-before: 0!important;
    -webkit-padding-start: 0!important;
    -webkit-margin-after: 0!important;
}
.container .list{
    margin-left:25px;
    font-size:12px!important
}
.container .yalty-theme {
    color:#4E525C !important;
    background-color:#00AEEF !important
}
.container .yalty-home{
    margin-left: 30px
}
.container .yalty_top{
    width:100%;
    height: 52px;
    z-index:10;
    margin-left: 0px;
    margin-top: 0px;
    position:fixed;
    color:#4E525C;
    background-color:#00AEEF !important
}
.container .overlay{
    position:fixed;
    display:none;
    width:100%;
    height:100%;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba(0,0,0,0.5);
    z-index:20;
    cursor: pointer;
}
.container .yalty-overlay{
    position:fixed;
    display:none;
    width:100%;
    height:100%;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba(0,0,0,0.5);
    z-index:20;
    cursor: pointer;
}
.container .yalty-overlay-white{
    position:fixed;
    display:none;
    width:100%;
    height:100%;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba(255,255,255,0.8);
    z-index:20;
    cursor: pointer;
}
.container .yalty-menu-overlay{
    position:fixed;
    display:none;
    width:100%;
    height:100%;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba(0,0,0,0.5);
    z-index:7;
    cursor: pointer;
}
.container .yalty-admin-menu-overlay{
    position:fixed;
    display:none;
    width:100%;
    height:100%;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:7;
    cursor: pointer;
}
.container .yalty-bar{
    width:100%;
}
.container .yalty-left-align{
    text-align:left!important
}
.container .yalty-right-align{
    text-align:right!important
}
.container .yalty-large{
    font-size:12px!important
}
.container .yalty-main-option{
    font-weight: bold;
    text-transform: uppercase;
}
.container .yalty_button{
    border:none;
    display:inline-block;
    outline:0;
    padding:8px 16px;
    vertical-align:middle;
    overflow:hidden;
    text-decoration:none;
    color:inherit;
    background-color:inherit;
    cursor:pointer;
    white-space:nowrap;
}
.container .activeSub{
    background-color:#00AEEF !important;
    color: white;
    font-weight: bold;
}
.container .active{
    background-color:#00AEEF !important;
    color: white;
    text-transform: uppercase;
}
.container .primary-button,.primary-button a{
    background-color:#00AEEF;
    color: white;
    text-transform: capitalize;
    width: 120px;
    text-decoration:none;
}
.container .primary-button.md-button:not([disabled]):hover{
    background-color:#00AEEF;
}
.container .inline-button {
    width: 150px;
    font-weight: bold;
    font-size: 80%;
    line-height: 30px;
    min-height: 30px;
    border-radius: 5px;
}
.container .finalize-button, .container .finalize-button.md-button:not([disabled]):hover{
    background-color:rgb(112, 173, 71);
    color: white;
    width: 150px;
    font-weight: bold;
    font-size: 80%;
    line-height: 30px;
    min-height: 30px;
    border-radius: 5px;
}
.container .finalize-button-reject, .container .finalize-button-reject.md-button:not([disabled]):hover{
    background-color:#e85858;
    color: white;
    width: 150px;
    font-weight: bold;
    font-size: 80%;
    line-height: 30px;
    min-height: 30px;
    border-radius: 5px;
}
.container .cancel-button{
    background-color:#4E525C;
    color: white;
    text-transform: capitalize;
    width: 120px;
}
.container .warn-button{
    background-color:#E85858;
    color: white;
    text-transform: capitalize;
    width: 120px;
}
.container .yalty-right{
    float:right!important;
    margin-right: 50px
}
.container .yalty-hide-large{
    display:none!important
}
.container .yalty-hover-turquoise:hover{
    color:#fff!important;
    background-color:#00AEEF!important;
}
.container .yalty-hover-turquoise2:hover{
    color:#fff!important;
}
.container .inactiveMenu{
    cursor:not-allowed;
    pointer-events: none;
    color:#DFDFDF;
}
.container .content .aside .yalty-sidebar{
    height: calc(100% - 52px);
    width: 205px;
    position:fixed!important;
    z-index:9;
    overflow:hidden;
    overflow-y: auto;
    border-right-style: solid;
    border-right-color: #D9D9D9;
    border-width: 1px;
    background-color: white;
}
.container .contentYaltyAdmin .asideYaltyAdmin .yalty-sidebar{
    height: calc(100% - 52px);
    width: 205px;
    position:fixed!important;
    z-index:9;
    overflow:hidden;
    overflow-y: auto;
    border-right-style: solid;
    border-right-color: #D9D9D9;
    border-width: 1px;
    background-color: white;
}
.container .yalty-sidebar .yalty-bar-item{
    width: 100%;
    box-sizing: border-box;
    white-space: pre-wrap;
    text-align: left;
}
.container .yalty-sidebar .yalty-bar-block{
    width:100%;
    text-align:left;
}
.container .selectedIndex {
    background-color: #00AEEF;
    border-radius: 20px;
}
.container .yalty-xlarge{
    font-size:20px!important
}
.container .yalty-padding-large{
    padding:0px 24px!important
}
.container .yalty-container{
    padding:1px 16px
}
.container .trBorder {
    border-bottom: 1px solid #ff0000;
}
.container h3{
    color:black;
}
.container .previewTemplatePlaceholderImage {
    border-radius: 50%;
}
#loginButton.md-button[disabled] {
    background-color: #FFF
}
#loginButton img {
    height: 20px;
    display: block;
    margin: auto;
}
.container .invalidGoogleMapMessageContainer {
    width: 650px;
    margin: auto;
    color: #ED7D31;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    overflow-y: scroll;
}

::-webkit-scrollbar-thumb {
    background: #dadce0;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #80868b;
    border-radius: 10px;
}

.container md-virtual-repeat-container#templateCategoryContainer ::-webkit-scrollbar,
.container md-virtual-repeat-container#templateStampContainer ::-webkit-scrollbar,
.container .yalty-sidebar::-webkit-scrollbar,
.container .main::-webkit-scrollbar,
.container .subscriptionPage::-webkit-scrollbar,
.container .subscriptionCardContainer::-webkit-scrollbar {
    width: 8px;
}
md-card.subscriptionCard md-card-content::-webkit-scrollbar,
.container .subscriptionLabels .labelsContainer::-webkit-scrollbar {
    width: 0;
}
/*.container md-virtual-repeat-container#templateCategoryContainer ::-webkit-scrollbar-track,
.container md-virtual-repeat-container#templateStampContainer ::-webkit-scrollbar-track,
.container .yalty-sidebar::-webkit-scrollbar-track,
.container .main::-webkit-scrollbar-track,
md-card.subscriptionCard md-card-content::-webkit-scrollbar-track,
.container .subscriptionLabels .labelsContainer::-webkit-scrollbar-track,
.container .subscriptionPage::-webkit-scrollbar-track,
.container .subscriptionCardContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}*/
.container md-virtual-repeat-container#templateCategoryContainer ::-webkit-scrollbar-thumb,
.container md-virtual-repeat-container#templateStampContainer ::-webkit-scrollbar-thumb,
.container .yalty-sidebar::-webkit-scrollbar-thumb,
.container .main::-webkit-scrollbar-thumb,
md-card.subscriptionCard md-card-content::-webkit-scrollbar-thumb,
.container .subscriptionLabels .labelsContainer::-webkit-scrollbar-thumb,
.container .subscriptionPage::-webkit-scrollbar-thumb,
.container .subscriptionCardContainer::-webkit-scrollbar-thumb,
.summary::-webkit-scrollbar-thumb,
#translationTable::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #dadce0; 
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/ 
}
.container md-virtual-repeat-container#templateCategoryContainer ::-webkit-scrollbar-thumb:hover,
.container md-virtual-repeat-container#templateStampContainer ::-webkit-scrollbar-thumb:hover,
.container .yalty-sidebar::-webkit-scrollbar-thumb:hover,
.container .main::-webkit-scrollbar-thumb:hover,
md-card.subscriptionCard md-card-content::-webkit-scrollbar-thumb:hover,
.container .subscriptionLabels .labelsContainer::-webkit-scrollbar-thumb:hover,
.container .subscriptionPage::-webkit-scrollbar-thumb:hover,
.container .subscriptionCardContainer::-webkit-scrollbar-thumb:hover,
.summary::-webkit-scrollbar-thumb:hover,
#translationTable::-webkit-scrollbar-thumb:hover {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #80868b; 
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/ 
}
.container md-virtual-repeat-container:not(#templateCategoryContainer):not(#templateStampContainer) ::-webkit-scrollbar{
    visibility: hidden;
}
.container md-input-container.baseStampColorsContainer label:not(.md-no-float):not(.md-container-ignore) {
    width: 80%;
}
.container md-input-container.baseStampColorsContainer.md-input-has-value label:not(.md-no-float) {
    width: 100%;
}
.container .md-button.md-default-theme.md-accent[disabled], .container .md-button.md-accent[disabled], .container .md-button.md-default-theme.md-fab[disabled], .container .md-button.md-fab[disabled], .container .md-button.md-default-theme.md-raised[disabled], .container .md-button.md-raised[disabled], .container .md-button.md-default-theme.md-warn[disabled], .container .md-button.md-warn[disabled], .container .md-button.md-default-theme[disabled], .container .md-button[disabled]{
    color:white;
    cursor: not-allowed;
}
.container md-select[disabled]#compStoreAddrCountry .md-select-value {
    color: inherit;
}
.container .md-button.md-default-theme[disabled], .container .md-button[disabled]{
    background-color: #DFDFDF;
}
.container md-input-container.md-default-theme .md-input, .container md-input-container .md-input {
    border-color:#4E525C;
    color: #4E525C;
}
.container md-input-container .minicolors-theme-bootstrap .minicolors-swatch{
    width: 20px;
    height: 20px;
}
.container md-input-container.md-default-theme .md-placeholder, .container md-input-container .md-placeholder, .container md-input-container.md-default-theme label, .container md-input-container label{
    color: #4E525C;
}
.container md-content.md-default-theme, .container md-content {
    color: #4E525C;
    background-color: white;
    overflow: hidden;
}
.container md-select.md-default-theme .md-select-value, .container md-select .md-select-value{
    border-bottom-color: #4E525C;
}
.container md-select:not([disabled]):focus .md-select-value{
    border-bottom-color: #00AEEF;
}
.container [disabled] md-input-container.md-default-theme .md-input, .container [disabled] md-input-container .md-input, .container md-input-container.md-default-theme .md-input[disabled], .container md-input-container .md-input[disabled]{
    color:#000;
    border-color:#4E525C;
    /*color:#DFDFDF!important;
    border-color:#DFDFDF!important;.disabledInputField*/
}
.container [disabled] md-input-container.md-default-theme .md-input, 
.container [disabled] md-input-container .md-input.disabledInputField, 
.container md-input-container.md-default-theme .md-input[disabled], 
.container md-input-container .md-input[disabled].disabledInputField{
    color:#DFDFDF!important;
    border-color:#DFDFDF!important;
}
md-dialog-content.md-dialog-content a {
    font-weight: bold;
    color: #ED7D31;
    cursor: pointer;
}
.md-dialog-content {
    max-width: 1000px;
  }
.container .higherPartnerSubscriptionTypeRequired,
.container md-checkbox.higherPartnerSubscriptionTypeRequired[disabled] .md-label,
.container md-checkbox.higherPartnerSubscriptionTypeRequired[disabled]:not(.md-checked) .md-icon,
.container md-radio-button.higherPartnerSubscriptionTypeRequired[disabled] .md-container .md-off,
.container md-select.higherPartnerSubscriptionTypeRequired[disabled] .md-select-value.md-select-placeholder,
.container md-select.higherPartnerSubscriptionTypeRequired[disabled] .md-select-value,
.container md-content.higherPartnerSubscriptionTypeRequired md-autocomplete md-input-container:not(.md-input-invalid).md-input-has-value label,
.container md-content.higherPartnerSubscriptionTypeRequired md-autocomplete md-input-container .md-input[disabled] {
    color:#85144B!important;
    border-color:#85144B!important;
}
.container md-checkbox.md-checked.higherPartnerSubscriptionTypeRequired[disabled] .md-icon {
    background-color: #85144B!important;
}
.container md-input-container.md-default-theme.md-input-invalid .md-input, .container md-input-container.md-input-invalid .md-input{
    border-color:#ED7D31;
    color:#ED7D31;
}
.container md-input-container.md-default-theme.md-input-invalid .md-char-counter, .container md-input-container.md-input-invalid .md-char-counter, .container md-input-container.md-default-theme.md-input-invalid .md-input-message-animation, .container md-input-container.md-input-invalid .md-input-message-animation, .container md-input-container.md-default-theme.md-input-invalid label, .container md-input-container.md-input-invalid label,.container md-input-container.md-default-theme .md-input-message-animation, .container md-input-container .md-input-message-animation, .container md-input-container.md-default-theme .md-input-messages-animation, .container md-input-container .md-input-messages-animation{
    color:#ED7D31;
}
.container md-input-container.md-input-invalid md-select.md-default-theme .md-select-value, .container md-input-container.md-input-invalid md-select .md-select-value{
    color: #ED7D31!important;
    border-bottom-color: #ED7D31!important;
}
.container md-select.md-default-theme.ng-invalid.ng-touched .md-select-value, .container md-select.ng-invalid.ng-touched .md-select-value, .container md-select.md-default-theme.ng-invalid.ng-touched .md-select-value, .container md-select.ng-invalid.ng-touched .md-select-value{
    color: #ED7D31!important;
    border-bottom-color: #ED7D31!important;
}
.container .md-default-theme .md-datepicker-input-container.md-datepicker-invalid, .md-datepicker-input-container.md-datepicker-invalid, .md-warn .md-default-theme .md-datepicker-input-container.md-datepicker-focused, .md-warn .md-datepicker-input-container.md-datepicker-focused{
    border-bottom-color: #ED7D31!important;
}
.container md-select.md-default-theme[disabled] .md-select-icon, .container md-select[disabled] .md-select-icon, .container md-select.md-default-theme[disabled] .md-select-value, .container md-select[disabled] .md-select-value, .container md-select.md-default-theme[disabled] .md-select-value.md-select-placeholder, .container md-select[disabled] .md-select-value.md-select-placeholder{
    /*color: #000000;
    border-color: #4E525C;*/
}
.container md-input-container.constantModifyField .md-input[disabled] + div .md-char-counter {
    color: #000000;
    border-color: #4E525C;
}
.container md-select[disabled].constantModifyField .md-select-icon,  
.container md-select[disabled].constantModifyField .md-select-value, 
.container md-select[disabled].constantModifyField .md-select-value.md-select-placeholder,
.container md-autocomplete.constantModifyField md-input-container label {
    color: #000000;
    border-color: #4E525C;
}
.container md-select[disabled].disabledInputField .md-select-icon,  
.container md-select[disabled].disabledInputField .md-select-value, 
.container md-select[disabled].disabledInputField .md-select-value.md-select-placeholder {
    color: #DFDFDF;
    border-color: #DFDFDF;
}
.container md-input-container .md-input[disabled] + div .md-char-counter {
    color:#DFDFDF;
}
.container md-select[disabled] .md-select-value {
    background-image: none;
}
.container md-input-container:not(.md-input-invlaid).md-input-focused .md-input, .container md-input-container:not(.md-input-invlaid).md-input-focused .md-input {
  border-color: #00AEEF;
  color:#00AEEF;
}
.container md-checkbox.md-default-theme.md-checked .md-ink-ripple, .container md-checkbox.md-checked .md-ink-ripple{
    color: #4E525C;
}
md-list-item .md-avatar, md-list-item .md-list-item-inner .md-avatar{
    height: unset;
    width: unset;
    max-width: 70px;
    max-height: 70px;
}
md-list-item.md-2-line .md-list-item-text h3, md-list-item.md-2-line > .md-no-style .md-list-item-text h3, md-list-item.md-3-line .md-list-item-text h3, md-list-item.md-3-line > .md-no-style .md-list-item-text h3{
    white-space: pre-wrap;
}
.container md-autocomplete[disabled].disabledInput md-autocomplete-wrap > md-input-container > label, .container md-autocomplete[disabled].disabledInput md-autocomplete-wrap > md-input-container > input{
    color: #DFDFDF;
    border-color: #DFDFDF;
}
.container md-autocomplete.sameAddressInput md-autocomplete-wrap > md-input-container > label, .container md-autocomplete.sameAddressInput md-autocomplete-wrap > md-input-container > input{
    color: #000000;
    border-color: #4E525C;
}
.container md-autocomplete.enabledInput md-autocomplete-wrap > md-input-container > label, .container md-autocomplete.enabledInput md-autocomplete-wrap > md-input-container > input{
    color: #4E525C;
    border-color: #4E525C;
}
.container .md-button.md-icon-button{
    /*margin: 0 -10px;*/
}
.container button.md-datepicker-button.md-icon-button{
    /*margin: 0 6px;*/
}
.container .md-icon-button + .md-datepicker-input-container{
    /*margin-left: 0;*/
}
.container .md-datepicker-input-container{
    width: 65%;
}
.container button.gm-control-active {
    outline: none;
}
/*md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label, md-input-container:not(.md-input-invalid).md-input-has-value label{
    color:#00AEEF;
}*/
.container md-input-container:not(.md-input-invalid).md-input-focused label{
    color:#00AEEF;
}
.container md-checkbox.md-default-theme.md-checked .md-icon, .container md-checkbox.md-checked .md-icon{
    background-color:#4E525C;
}
.redCheckbox md-checkbox.md-default-theme.md-checked .md-icon, .redCheckbox md-checkbox.md-checked .md-icon{
    background-color:#FB6347;
}
.redCheckbox md-checkbox:not(.md-checked) .md-icon{
    border-color: #FB6347;
}
.container .templateIconContainer md-checkbox.md-default-theme.md-checked .md-icon, .container .templateIconContainer md-checkbox.md-checked .md-icon{
    background-color:#00AEEF;
}
.container .templateIconContainer md-checkbox.md-default-theme:not(.md-checked) .md-icon, .container .templateIconContainer md-checkbox:not(.md-checked) .md-icon {
    border-color: #00AEEF;
}
.container .md-checkbox-enabled.md-default-theme[selected] .md-icon, .container .md-checkbox-enabled[selected] .md-icon{
    background-color:#4E525C;
}
.container md-card.md-default-theme, .container md-card{
    background-color: rgb(226,240,217);
}
.container md-slider.md-default-theme .md-thumb:after, md-slider .md-thumb:after, md-slider.md-default-theme.md-min .md-thumb:after, md-slider.md-min .md-thumb:after {
    border-color: #80868b; 
    background-color: #80868b; 
}
md-slider:not([md-discrete]):not([disabled]).md-active .md-slider-wrapper .md-thumb{
    transform: none;
    -webkit-transform: none;
}
.container md-slider.md-default-theme .md-track.md-track-fill, md-slider .md-track.md-track-fill{
    background-color: #4E525C; 
}
.container md-radio-button.md-default-theme .md-on, md-radio-button .md-on {
    background-color: #4E525C; 
}
.container md-radio-button.md-default-theme.md-checked .md-off, md-radio-button.md-checked .md-off {
    border-color: #4E525C;
}
.container md-card{
    margin:0;
    margin-top:8px;
}
.container .largeWidthInputs{
    width:500px;
}
.container .middleWidthInputs{
    width:270px;
}
.container .middleWidthInputParted{
    width:230px;
}
.container .smallWidthInputs{
    width: 140px;
}
.container .partnerDataMultiButtonContainer{
    height: 130px;
}
.container .partnerDataButtonContainer{
    height: 48px;
}

.container .subscriptionPage .hoveredRow {
    background-color: #F2F2F2;
}
.container .subscriptionPage .firstLineHeight {
    height: 70px;
}
.container .subscriptionPage .secondLineHeight {
    height: 60px;
}
.container .subscriptionPage .lineHeight {
    height: 2em;
}
.container .subscriptionLabels {
    box-sizing: border-box;
    padding-top: 66px;
    color: rgb(78,82,92);
}
.container .subscriptionLabels .labelsContainer {
    height: 100%;
}
.container .subscriptionLabels ion-icon {
    margin: auto 5px;
    font-size: 125%;
}
.container .subscriptionLabels p {
    margin: 0;
}
.container .subscriptionLabels ul {
    padding-left: 20px;
}
.container .subscriptionLabels ul > li {
    list-style-type: disc;
    margin: 5px 0;
}
.container #horizontal-container {
    width: 50%;
}
md-card.subscriptionCard {
    border-radius: 10px;
    margin: 8px;
    min-width: 180px!important;
    max-width: 275px!important;
    background-color: #FFF;
    color: rgb(78,82,92);
    cursor: pointer;
    outline: none;
    box-shadow: 0 10px 40px 0 rgba(62,57,107,.07), 0 2px 9px 0 rgba(62,57,107,.06)
}
md-card.subscriptionCard:not(.selectedSubscription) .secondLineHeight {
    color: rgb(127,127,127);
}
md-card.subscriptionCard.selectedSubscription {
    border: 1px solid #00AEEF;
    border-radius: 10px;
    color: #00AEEF;
}
md-card.subscriptionCard.selectedSubscription .price-button,
md-card.subscriptionCard.unselectedSubscription .price-button:hover {
    color: #FFF;
    background-color: #00AEEF;
}
md-card.subscriptionCard.unselectedSubscription .price-button,
md-card.subscriptionCard.selectedSubscription .price-button:hover {
    color: #00AEEF;
    background-color: #FFF;
}
md-card.subscriptionCard md-card-title {
    flex: unset;
}
md-card.subscriptionCard md-card-title md-card-title-text span {
    color: #00AEEF;
    font-weight: bold;
    font-size: 110%;
    text-align: center;
}
md-card.subscriptionCard md-card-content {
    text-align: center;
    height: 100%;
}
/*md-card.subscriptionCard md-card-content div:not(.separatedRow):hover {
    background-color: #F2F2F2;
}*/
.container .select-header {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
    padding-left: 10.667px;
    height: 48px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    width: auto; 
}
.container .td1{
    width: 100%;
    max-width: 100%;
}
.container .td13{
    max-width: 40%;
    word-wrap: break-word;
}
.container .td2{
    width: 70%;
    max-width: 70%;
}
.container .td21{
    width: 60%;
    max-width: 60%;
    word-wrap: break-word;
}
.container #design{
    width: 50%;
}
.container #construct{
    width: 50%;
}
.container #photo{
    width: 75%;
}
.yalty-animateFrom-mobile{
    position:relative;
    animation:animateFromMobile 0.1s forwards;
}@keyframes animateFromMobile{
    from{
        left:0px;
    }to{
        left:295px;
    }
}
.yalty-animateTo-mobile{
    position:relative;
    animation:animateToMobile 1s forwards;
}@keyframes animateToMobile{
    from{
        left:295px;
    }to{
        left:0px;
    }
}
.animateFromUserAccounts{
    position:relative;
    animation:animateFromUserAccount 0.1s forwards;
}@keyframes animateFromUserAccount {
    from {
        left: -295px;
    } to {
        left: 0px;
    }
}
.animateToUserAccounts{
    position:relative;
    animation:animateToUserAccount 1s forwards;
}@keyframes animateToUserAccount {
    from {
        left: 0px;
    } to {
        left: -295px;
    }
}
.container #invalidErrorInRedeemStartDate, 
.container #invalidErrorInRedeemEndDate, 
.container #requiredErrorInRedeemStart, 
.container #requiredErrorInDisplayStart, 
.container #requiredErrorInRedeemEnd, 
.container #invalidErrorInDisplayStart {
    font-size: 12px;
    color: #ED7D31;
    margin-left: 52px; 
 }
 .container #invalidErrorInRedeemStartDateInBeginTime, 
 .container #invalidErrorInRedeemEndDateInEndTime, 
 .container #invalidErrorInDisplayStartDate {
     font-size: 12px;
     color: #ED7D31;
     margin-top: 35px; 
  }
  .container #invalidErrorInYaltyCity{
    font-size: 12px;
    color: #ED7D31;
    margin-top: 5px;
  }
.container .borderListMobile{
    position:relative;
    z-index: 5;
    width: 275px;
    max-width: 275px;
    max-height: 550px;
    border-style: solid;
    border-width: 61px 16px 413px 16px;
    background-color: #f2f3f4;
    border-radius: 40px;
    table-layout: fixed;
    -moz-border-image: url(/imgs/mobileBorder.png) 61 16 413 16 stretch repeat;
    -webkit-border-image: url(/imgs/mobileBorder.png) 61 16 413 16 stretch repeat;
    -o-border-image: url(/imgs/mobileBorder.png) 61 16 413 16 stretch repeat;
    border-image: url(/imgs/mobileBorder.png) 61 16 413 16 stretch repeat;
}
.container .borderDetailMobile{
    z-index: 5;
    width: 275px;
    max-width: 275px;
    max-height: 550px;
    border-style: solid;
    border-width: 61px 16px 62px 16px;
    table-layout: fixed;
    -moz-border-image: url(/imgs/mobileBorder.png) 61 16 62 16 stretch ;
    -webkit-border-image: url(/imgs/mobileBorder.png) 61 16 62 16 stretch ;
    -o-border-image: url(/imgs/mobileBorder.png) 61 16 62 16 stretch ;
    border-image: url(/imgs/mobileBorder.png) 61 16 62 16 stretch ;
}

.mobile-preview-container {
    margin: 13px;
    position: relative;
}

.mobile-detail-preview {
    z-index: 1;
}

.mobile-detail-preview td,
.mobile-list-preview td {
    padding: 0;
    display: block;
}

.mobile-detail-preview .completion {
    height: 22px;
    width: 240px;
}

.mobile-list-preview .completion {
    height: 22px;
    width: 240px;
    margin-left: 13px;
}

.mobile-detail-preview .notch {
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    margin-top: -3px;
}

.mobile-list-preview .notch {
    margin-bottom: -34px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
}

.mobile-detail-preview .lower-part,
.mobile-list-preview .lower-part {
    margin-top: -34px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
}

.iphone-frame--list-view {
    z-index: 5;
    border-style: solid;
    border-spacing: 0;
    border-width: 34px 13px 34px 13px;
    border-image: url(/imgs/iPhone_12_Pro_silver_580x1174px.png) 80 33 80 33;
    min-height: 470px;
    width: 240px;
}

.iphone-frame--detail-view {
    z-index: 5;
    border-style: solid;
    border-spacing: 0;
    border-width: 34px 13px 34px 13px;
    border-image: url(/imgs/iPhone_12_Pro_silver_580x1174px.png) 80 33 80 33;
    width: 239px;
    min-height: 470px;
    position: absolute;
    left: -10px;
    top: -13px;
    bottom: -10px;
    right: -11px;
}

.mobile-detail-preview .list-view-background,
.iphone-frame--list-view .list-view-background {
    width: 240px;
    background-color: #f2f3f4;
    height: 470px;
}

.mobile-detail-preview td,
.iphone-frame--list-view {
    padding: 0;
}

.container #vertical-container {
    height: 440px;
    width: 300px;
}
.container .couponListView{
    width: 235px;
    table-layout: fixed;
}
.container .storeListView{
    width: 220px;
}
.container .btn {
    background-color: #00AEEF;
    width: 260px;
    text-align: left;
}
.container .activeBtn {
    background-color: #00AEEF;
    width: 260px;
    text-align: left;
}
.container .md-button.md-primary.md-raised.yaltyBlueButton {
    background-color: #00AEEF;
}
.container .md-button[disabled].md-primary.md-raised.yaltyBlueButton, .container .md-button.md-raised[disabled].md-primary.md-raised.yaltyBlueButton{
    background-color: #DFDFDF;
}
.container .settingArea{
    background: #E4E4E4;
    overflow: hidden;
    width:300px;
    height:200px;
    position: absolute;
    top: 30%;
    left: 70%;
}
.container .cropArea {
    /*background: #E4E4E4;
    overflow: hidden;
    width:45%;
    height:340px;*/
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.container .couponDetailCropArea{
    /*background: #E4E4E4;
    overflow: hidden;*/
    width:43%;
    height:68%;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
}
.container .couponListCropArea{
    /*background: #E4E4E4;
    overflow: hidden;*/
    width:43%;
    height:68%;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
}
.container .cropEndButton{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top:300px;
    margin-left: -50px;
}
.container .cropSelectButton{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top:300px;
    margin-left: 50px;
}
.container .dropbox {
    background: #F8F8F8;
    border: 2px dashed #4E525C;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    padding-top: 25px;
    margin-left: 10px;
}
.container .dropbox:hover{
    cursor: pointer;
}
.container .previewContainer{
    width:185px;
    position:relative;
}
.container .preview{
    max-width:185px;
    max-height: 109px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.container .dragover {
    background: rgb(223,223,223);
}
.container .combocontainer{
    display:inline;
    position: absolute;
    right: 0%;
}
.container .comboinput {
    position:relative;
    top:-8px;
    left:-6em;
}
.container .comboinput div._md-text{
    display:none !important;
}
.container .comboinput md-select-value:not([disabled]):focus ._md-select-value{
    border-bottom:none;
}
.container .comboinput md-select-value{
    min-width: 0.0em;
    border-bottom:none !important;
}
.container .stampedBackground {
    background-color: #4E525C;
    color: #FFF;
    border: 1px solid transparent;
}

.container .giftStampedBackground {
    background-color: #4E525C;
    color: #FFF;
    border: 2px rgb(255,255,255) solid;
}

.container .unstampedBackground {
    background-color: #DFDFDF;
    color: #4E525C;
    border: 1px solid transparent;
}

.container .giftUnstampedBackground {
    background-color: #DFDFDF;
    color: #4E525C;
    border: 2px rgb(255,255,255) solid;
}

.container .giftCollectedBackground {
    background-color: #DFDFDF;
    color: #4E525C;
    border: 2px #00AEEF solid;
}

.container .unstampedUnredeemedBackground {
    background-color: #DFDFDF;
    color: #4E525C;
    border: 1px rgb(78,82,92) solid;
}
.container .activeStampedBackground {
    background-color: #00AEEF;
    color: #FFF;
    border: 1px solid transparent;
}

.container .giftActiveStampedBackground {
    background-color: #00AEEF;
    color: #FFF;
    border: 2px rgb(255,255,255) solid;
}

.container .activeStampedUnredeemedBackground {
    background-color: #DFDFDF;
    color: #FFF;
    border: 1px #00AEEF solid;
}
.container .activeCashingBackground {
    color: #00AEEF;
    background-color: rgb(230, 248, 255);
}
.container .inactiveCashingBackground {
    color: #4E525C;
    background-color: rgb(242, 242, 242);
}
.container .activeIncreaseButton {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: auto;
    outline: none;
    cursor: pointer;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.container .activeIncreaseButton img {
    width: 60px;
    height: 60px;
}
.container .redeemStatusDetailContainer{
    margin: 15px auto;
    display: block;
}
.container .activeFinalize {
    color: #FFF;
    font-size: 70%;
    font-weight: bold;
    list-style-type: initial;
}
.container .acceptedFinalize {
    color: #4E525C;
    font-size: 70%;
    font-weight: bold;
    list-style-type: initial;
}
.container .inactiveFinalize {
    color: #4E525C;
    font-size: 70%;
    list-style-type: initial;
}
.container .cashingDiscountMargin{
    margin-bottom: 10%;
}
.container .cashingDiscountMobileMargin{
    margin-bottom:10%;
}
.container .stampIncreaseContainer{
    width: 40%
}
.container .rejectCashingButtonContainer{
    width: 40%;
    padding-top: 80px;
}
.container #stampListContainer{
    width: 60%
}
.container .redeemName {
    font-size: 110%;
}
.container #stampListContainer .stampListContainerElement{
    margin-bottom: 10px;
}
md-select-header md-checkbox.md-checked .md-icon,
.md-checkbox-enabled[selected] .md-icon,
md-select-header md-checkbox[disabled].md-checked .md-icon{
    background-color: #4E525C;
}
md-select-header  md-checkbox:not(.md-checked) .md-icon,
.md-checkbox-enabled:not(.md-checked) .md-icon {
    border-color: #4E525C;
}
md-option.stampIconSizingOption .md-text{
    width: 100%;
    white-space: unset;
}
md-option.stampIconSizingOption .md-text .stampIconSizingImage{
    width: 40px;
    margin-top: 4px;
}
md-option.stampIconSizingOption .md-text .stampIconSizingDiv{
    position: relative;
}
md-option.stampIconSizingOption .md-text .stampIconSizingDiv span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.container .stampIconSizingSelect .md-text .stampIconSizingImage {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.container .stampIconSizingSelect .md-text .stampIconSizingDiv {
    position: relative;
    white-space: pre-wrap;
}
.container .stampIconSizingSelect .md-text .stampIconSizingDiv span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.container .finalizeTextContainer{
    width: 60%;
}
.container .finalizeButtonContainer{
    width: 40%;
}
.container .cashingLoyaltyCard, .container .cashingCouponCard {
    width: 500px;
}
.container .cashingTable{
    border-collapse:collapse;
}
.container .cashingTable td{
    padding: 5px;
}
.container .cashingStatus,.container .mobileCashingStatus{
    display: block;
    text-align:center;
    padding:0 15px;
    font-weight:bold;
}
.container .mobileCashingStatus{
    display: none;
}
.container .acceptCashingButton{
    background-color:#00AEEF;
    color:white;
    padding:0 15px;
    line-height: 20px;
    min-height: 25px;
    display: block;
    margin:auto;
}
.container .preregLandingList {
    margin-left: 25px;
}
.container .preregLandingList li {
    margin-bottom: 15px;
    font-size: 100%;
}
.container .preregLandingList li p {
    margin-top: 10px;
    margin-bottom: 5px;
}
.container .preregLandingList li > div{
    width: 640px;
}
.container .listImageContainer img {
    max-width: 40px;
    display: block;
    margin: auto;
}
.container .listImageContainer img.loyaltyImage,
.container .listImageContainer img.freeBasicImage {
    max-width: 35px;
}
.container .listImageContainer img.loyaltyImage {
    margin-right: 5px;
}
.container .listImageContainer {
    margin-right: 15px;
    width: 40px;
}
.container .priceButtonContainer {
    padding: 20px 10px;
}
.container .price-button {
    color: #00AEEF;
    background-color: #FFF;
    border: 2px #00AEEF solid;
    border-radius: 25px;
    padding: 5px 30px;
    margin: auto;
    display: block;
    font-size: 15px;
    line-height: 25px;
    min-height: 25px;
}
.container .priceButtonContainer .price-button {
    color: #FFF;
    background-color: #00AEEF;
}
.container .priceButtonContainer .price-button:hover {
    background-color: #FFF;
    color: #00AEEF;
}
.container .priceButtonContainer .price-button.subscription-button {
    background-color: #FFF;
    color: #00AEEF;
}
.container .priceButtonContainer .price-button.subscription-button:hover {
    color: #FFF;
    background-color: #00AEEF;
}
.container .price-button:hover {
    color: #FFF;
    background-color: #00AEEF;
}
.container.paymentRedirectContainer .price-button {
    min-width: 200px;
    padding: 0 30px;
}
.container.paymentRedirectContainer .price-button:hover {
    border: 2px #FFF solid;
}
.container .cancelCashingButton{
    background-color:#e85858;
    color:white;
    padding:0 15px;
    line-height: 20px;
    min-height: 25px;
    display: block;
    margin:auto;
}
.container #subscriptionRow3 {
    margin-top: 10px;
}
.container #subscriptionRow6,
.container #subscriptionRow7,
.container #subscriptionRow8,
.container #subscriptionRow9,
.container #subscriptionRow10,
.container #subscriptionRow11,
.container #subscriptionRow12,
.container #subscriptionRow13,
.container #subscriptionRow14,
.container #subscriptionRow17,
.container #subscriptionRow24,
.container #subscriptionRow19,
.container #subscriptionRow25,
.container #subscriptionRow20,
.container #subscriptionRow21,
.container #subscriptionRow22,
.container #subscriptionRow23/*,
.container #subscriptionRow26,
.container #subscriptionRow27*/ {
    margin: 5px 0;
}

@media (max-width:600px), (max-width:600px) and (orientation:landscape){
    .container #subscriptionRow24,
    .container #subscriptionRow19,
    .container #subscriptionRow25 {margin: 25px 0;}
}

.container #subscriptionRow4,
.container #subscriptionRow11,
.container #subscriptionRow14,
.container #subscriptionRow17,
.container #subscriptionRow19,
.container #subscriptionRow23,
.container #subscriptionRow25,
.container #subscriptionRow26/*,
.container #subscriptionRow27*/ {
    margin-bottom: 20px;
}
payment-summary .paymentSummary {
    padding: 0 18px 5px;
    border-bottom: #DFDFDF solid 1px;
}
payment-summary table.paymentSummaryTable {
    margin: auto;
    width: 100%;
    color: #4E525C;
    font-size: 90%;
    border-collapse: collapse;
}
payment-summary table.paymentSummaryTable tr th {
    text-align: center;
    font-weight: bold;
    padding: 10px;
}

payment-summary table.paymentSummaryTable thead {
    border-top: #D9D9D9 solid 1px;
    border-bottom: #D9D9D9 solid 1px;
}

payment-summary table.paymentSummaryTable tbody {
    border-top: #D9D9D9 solid 1px;
    border-bottom: #D9D9D9 solid 1px;
}

payment-summary table.paymentSummaryTable tr th:last-child,
payment-summary table.paymentSummaryTable tr td:last-child {
    text-align: right;
}
payment-summary table.paymentSummaryTable tr td {
    text-align: center;
}
payment-summary table.paymentSummaryTable tr th:nth-child(2),
payment-summary table.paymentSummaryTable tr td:nth-child(2),
payment-summary table.paymentSummaryTable tr th:nth-child(3),
payment-summary table.paymentSummaryTable tr td:nth-child(3) {
    text-align: left;
}
payment-summary .paymentSummaryContainer {
    margin-right: 10px;
}
payment-summary .paymentSummaryContainer > div:first-child {
    margin-right: 25px;
}
payment-summary .paymentSummaryContainer p {
    margin-bottom: 0;
    font-size: 90%;
    color: #4E525C;
}
payment-summary .subscriptionEmailContainer {
    width: 70%;
}
payment-summary .subscriptionEmailContainer > div {
    width: 90%;
}
payment-summary .subscriptionEmailContainer md-input-container {
    margin: 0;
    padding: 2px 0;
}
payment-summary .payingDetailsContainer {
    width: 65%;
    color: #4E525C;
    /*margin-bottom: 14px;*/
}
payment-summary .payingDetailsContainer img {
    width: 95%;
}
.paymentRedirectContainer{
    background-color:#00AEEF;
    height: 100%;
    width: 100%;
}
.paymentRedirectContainer p {
    width: 80%;
    text-align: center;
    font-weight: bold;
    color: #FFF;
}
.unsubscribeContainer{
    background-color:#00AEEF;
    height: 100%;
    width: 100%;
}
.unsubscribeContainer p {
    width: 80%;
    text-align: center;
    font-weight: bold;
    color: #FFF;
}
.container #map .centerMarker{
    position:absolute;
    /*url of the marker*/
    background:url(http://maps.gstatic.com/mapfiles/markers2/marker.png) no-repeat;
    /*center the marker*/
    top:50%;left:50%;
    z-index:1;
    /*fix offset when needed*/
    margin-left:-10px;
    margin-top:-34px;
    /*size of the image*/
    height:34px;
    width:20px;
    cursor:pointer;
  }
  .container .overlay .spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
}

.container .overlay .spinner>div {
    width: 18px;
    height: 18px;
    background-color: #00AEEF;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.container .overlay .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.container .overlay .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
#innerPartnerPageContainer .preregistrationsList{
    overflow: auto;
}
#innerPartnerPageContainer .preregistrationsList md-virtual-repeat-container.md-virtual-repeat-container .md-virtual-repeat-scroller {
    overflow-x: auto;
}
#innerPartnerPageContainer .preregistrationsList md-virtual-repeat-container[id*="scrollContainer"] ::-webkit-scrollbar{
    height: 8px;
    width: 8px;
}
/*#innerPartnerPageContainer .preregistrationsList md-virtual-repeat-container[id*="scrollContainer"] ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}*/
#innerPartnerPageContainer .preregistrationsList md-virtual-repeat-container[id*="scrollContainer"] ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #dadce0; 
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/ 
}
#innerPartnerPageContainer .preregistrationsList md-virtual-repeat-container[id*="scrollContainer"] ::-webkit-scrollbar-thumb:hover {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #80868b; 
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/ 
}

md-virtual-repeat-container[id*="leaderboardTable"] ::-webkit-scrollbar{
    height: 8px;
    width: 8px;
}
/*md-virtual-repeat-container[id*="leaderboardTable"] ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}*/
md-virtual-repeat-container[id*="leaderboardTable"] ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #dadce0; 
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/ 
}
md-virtual-repeat-container[id*="leaderboardTable"] ::-webkit-scrollbar-thumb:hover {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #80868b; 
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/ 
}

#leaderboardTable .md-virtual-repeat-scroller {
    overflow-y: hidden;
    overflow-x: auto;
}

#userNumberRetentionStat2 .md-virtual-repeat-scroller {
    overflow-y: hidden;
}

.idInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
#moreStore::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
#morePartnerUser::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

[id*="scrollContainer"]::-webkit-scrollbar{
    height: 8px;
    width: 8px;
}
/*[id*="scrollContainer"]::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}*/
[id*="scrollContainer"]::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #dadce0; 
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/ 
}
[id*="scrollContainer"]::-webkit-scrollbar-thumb:hover {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #80868b; 
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);*/ 
}


#innerPartnerPageContainer md-toast.md-center {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
}
#innerPartnerPageContainer .preregistrationsList table {
    border-spacing: 0px;
    width: 3315px;
    table-layout: fixed;
    overflow-wrap: break-word;
}
#innerPartnerPageContainer .preregistrationsList table td {
    color: #4E525C;
    border: 1px solid #F2F2F2;
    padding: 5px;
    font-size: 80%;
}
#innerPartnerPageContainer .preregistrationsList#prereglist table td:nth-child(1),
#innerPartnerPageContainer .preregistrationsList#prereglist table td:nth-child(2), 
#innerPartnerPageContainer .preregistrationsList#prereglist table td:nth-child(4),
#innerPartnerPageContainer .preregistrationsList#prereglist table td:nth-child(7){
    text-align: center;
}
#innerPartnerPageContainer .preregistrationsList#partnerhistorylist table td:nth-child(1),
#innerPartnerPageContainer .preregistrationsList#partnerhistorylist table td:nth-child(3){
    text-align: center;
}
#innerPartnerPageContainer .preregistrationsList#partnerpushnotificationlist table td:nth-child(1),
#innerPartnerPageContainer .preregistrationsList#partnerpushnotificationlist table td:nth-child(2){
    text-align: center;
}
#innerPartnerPageContainer .preregistrationsList#companyList table td:nth-child(1),
#innerPartnerPageContainer .preregistrationsList#companyList table td:nth-child(2),
#innerPartnerPageContainer .preregistrationsList#companyList table td:nth-child(3){
    text-align: center;
}
#innerPartnerPageContainer .preregistrationsList table th{
    text-align: center;
    background-color: #00AEEF;
    font-weight: bold;
    color: #FFF;
    padding: 5px;
    font-size: 80%;
}

.blackSpan {
    color: #000;
}

.greyCompRecordTableField {
    color:#CFCFCF !important; 
    margin: 0;
}

.normalCompRecordTableField {
    margin: 0;
}

.normalCompRecordTableField span, .normalCompRecordTableField ion-icon {
    color: #000 !important;
}

.alignToolTipTextInCompRecordTable {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

#innerPartnerPageContainer .preregistrationsList#prereglist table.portfolioTable {
    width: 400px;
    font-style: normal; 
    font-size: 14px; 
    color: rgb(78,82,92);
}

#innerPartnerPageContainer .preregistrationsList#prereglist table.portfolioTable td {
    text-align:  left;
    border: none;
}

#innerPartnerPageContainer .preregistrationsList#prereglist .portfolioTable .attribution {
    text-align: center;
}

#innerPartnerPageContainer .preregistrationsList#prereglist .portfolioTable .attribution .valueOneOrKO {
    color: #f4cccc;
    background-color: rgb(255, 0, 0);
    font-weight: bold;
}

#innerPartnerPageContainer .preregistrationsList#prereglist .portfolioTable .attribution .valueTwoOrTwoAndHalf {
    color: #990000;
    background-color: rgb(234, 153, 153);
}

#innerPartnerPageContainer .preregistrationsList#prereglist .portfolioTable .attribution .valueThreeOrHyphen {
    color: black;
    background-color: rgb(239, 239, 239);
}

#innerPartnerPageContainer .preregistrationsList#prereglist .portfolioTable .attribution .valueFour {
    color: #38761d;
    background-color: rgb(182, 215, 168);
}

#innerPartnerPageContainer .preregistrationsList#prereglist .portfolioTable .attribution .valueFive {
    color: #38761d;
    background-color: rgb(0, 255, 0);
    font-weight: bold;
}

#innerPartnerPageContainer .preregistrationsList#prereglist .companyStatus {
    text-align: center; 
    color: red;
}

#innerPartnerPageContainer .preregistrationsList#prereglist .companyStatus .tooltip {
    margin: 0;
    margin-left: 20px;
}

#innerPartnerPageContainer .preregistrationsList#prereglist .companyStatus .yaltytiptextbottom {
    width: 150px;
    right: -25px;
    top: 25px;
}

#innerPartnerPageContainer .preregistrationsList#prereglist .companyStatus .companyStatusContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

#innerPartnerPageContainer .preregistrationsList#prereglist .companyStatus ion-icon {
    width: 20x;
    margin: 0;
    margin-top: 3px;
    color: #4E525C;
}

#userNumberChart, #appUsageChart {
    margin: 0 auto;
}

#earlyDateError,
#earlyDateErrorForStat1,
#earlyDateErrorForStat2,
#earlyDateErrorForStat3  {
    color: #ED7D31;
    display: flex;
    max-width: 1400px;
    align-items: center;
    margin-bottom: 30px;
    margin-top: -25px;
}

#earlyDateError i,
#earlyDateErrorForStat1 i,
#earlyDateErrorForStat2 i,
#earlyDateErrorForStat3 i {
    color: #ED7D31;
    margin: 0;
    margin-right: 20px;
}

.tooltip .infoTextContainer {
    display: flex;
    visibility: hidden;
    position: absolute;
}

.infoTextRight {
    z-index: 6;
    background: #fff;
    letter-spacing: -0.05px;
    border-radius: 8px;
    box-shadow: 0 1px 1px 0 rgba(60,64,67,.08), 0 1px 3px 1px rgba(60,64,67,.16);
    padding: 10px;
    width: 300px;
    left: 30px;
    position: absolute;
    top: -40px;
    color: #5c5f62;
    font-family: "Roboto",sans-serif;
    font-size:12px;
    font-style: normal;
    line-height: 16px;
    font-weight: 400;
    margin: 0;
}   

.infoTextRight:before {
    position: absolute;
    z-index: 6;
    height: 15px;
    width: 15px;
    left: -7px;
    top: 30px;
    content: "\0000a0";
    background: #fff;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.tooltip:hover .infoTextContainer {
    visibility: visible;
    opacity: 1;
}

.clipboardButton {
    width: 45px;
    margin: 5px;
    margin-top: 20px;
    cursor: pointer;
}

.middleMessageOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 21;
}

#okMessageAfterCopy {
    display: none;
}

.middleMessageContainer {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
}

.middleMessageText {
    font-weight: bold;
    color: rgba(255,255,255,1);
    padding: 10px;
}

.okIcon {
    height: 30px;
    width: 30px;
    margin: 10px;
}

.loadingIndicatorIcon {
    height: 40px;
    width: 40px;
    margin: 5px;
    padding: 0;
}

.mdDialogList {
    margin-left: 40px;
}

#potentialCompaniesPage ._md-center {
    left: 50%;
    /*top: 50%;*/
    /*transform: translate3d(-50%, 0, 0);*/
}

.stat1InfoIconContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

td .stat1InfoIconContainer .stat1InfoIcon {
    margin: 0; 
    margin-left: 5px;
}

td .stat1InfoIconContainer .stat1InfoIcon .yaltytiptextright2 {
    top: -20px;
    bottom: -10px;
}

#cardValidityDuration md-input-container label {
    overflow: unset !important;
}

.measurePromotionNameWidth, .measurePromotionName2ndLineWidth, .measurePromoFreeTextWidth {
    font:inherit;
    margin:0;
    padding:0;
    display:none;
    white-space:pre;
    min-width: 300px;
    text-transform: uppercase;
}

.marketingPackageContainer {
    display: flex;
    margin-top: 50px;
}

.marketingPackageContainer .marketingPackagePreview {
    display: flex;
    flex-direction: column;
}

.marketingPackageContainer .marketingPackageSettings {
    display: flex;
    flex-direction: column;
}

.marketingPackageContainer .marketingPackageSettings .sectionTitle {
    margin-bottom: 30px;
}

.containerWithShadow {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 15px #cccccc;
    margin-bottom: 40px;
    width: 620px;
    padding: 0 10px 10px 10px;
}

.containerWithShadow .generationButtonContainer {
    display: flex;
    align-items: center;
}

.containerWithShadow .facebookTextButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.containerWithShadow .facebookTextContainer {
    padding: 15px;
    border-style: solid;
    border-radius: 10px;
    border-color: #4E525C;
}

.textarea_width_info_icon md-input-container {
    margin-top: 35px; 
    width: 580px; 
    vertical-align: top;
}

.textarea_width_info_icon i {
    margin-top: 40px;
}

.containerWithShadow .alert-msg {
    color: #ED7D31;
    margin-left: 20px;
}

.item-container {
    display: flex;
    align-items: center;
}

.containerWithShadow>div {
    margin-top: 20px;
}

.containerWithShadow>.section_start {
    margin-top: 40px;
}

.tooltip.hidePointer .yaltytiptext::after {
    display: none;
}

.tooltip .listInInfoText {
    color: #5c5f62;
    font-family: "Roboto",sans-serif;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    font-weight: 400;
    margin: 0;
    margin-left: 20px;
}

.tooltip .listInInfoText li {
    display: list-item;
    list-style-type: disc;
}

.tooltip.warning {
    color: orange;
    height: 20px;
}

.tooltip.warning div{
    bottom: 35px;
}

.LCScaledCanvasContainer {
    position: relative;
    width: 480px;
    height: 252px;
}

.LCScaledCanvasContainer .backgroundImg, .LCScaledCanvasContainer .canvas {
    position: absolute; 
    top: 0px; 
    left: 0px;
}

.LCOriginalCanvasContainer {
    position: relative;
    width: 1200px;
    height: 630px;
}

.LCOriginalCanvasContainer .backgroundImg, .LCOriginalCanvasContainer .canvas {
    position: absolute; 
    top: 0px; 
    left: 0px;
}

.A5ScaledCanvasContainer {
    position: relative;
    width: 451px;
    height: 640px;
}

.A5ScaledCanvasContainer .backgroundImg, .A5ScaledCanvasContainer .canvas {
    position: absolute; 
    top: 0px; 
    left: 0px;
}

.A5OriginalCanvasContainer {
    position: relative;
    width: 902px;
    height: 1280px;
}

.A5OriginalCanvasContainer .backgroundImg, .A5OriginalCanvasContainer .canvas {
    position: absolute; 
    top: 0px; 
    left: 0px;
}

.loyalty-card-list-item-container {
    height: 114px;
    width: 230px;
    margin: 0 auto;
    border-radius: 11px; 
    box-sizing: border-box;
    border: 1px solid;
    padding-left: 12.41px;
    padding-bottom: 8.38px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Helvetica-preview';
}

.loyalty-card-list-item-container .promo-name {
    font-size: 9.37px;
    margin-top: 3.02px;
}

.loyalty-card-list-item-container .comp-name-promo {
    font-size: 12px;
    font-family: 'Helvetica-bold-preview';
}

.loyalty-card-list-item-container .promo-name-container {
    display: flex;
    flex-direction: column;
    margin-top: 17.23px;
    width: 150.2px;
}

.loyalty-card-list-item-container .card-logo {
    margin-left: auto;
    margin-right: 12px;
    margin-top: 8px;
    height: 40px;
    width: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.loyalty-card-list-item-container img {
    width: 100%;
}

.loyalty-card-list-item-container .stamp-container {
    height: 21.46px;
    display: flex;
    align-items: center;
}

.loyalty-card-list-item-container .stamp-numbers-container {
    padding: 0 8.05px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21.45px;
    box-sizing: border-box;
}

.loyalty-card-list-item-container .stamp-container .stamp-numbers {
    font-size: 12px;
    font-family: 'Helvetica-bold-preview';
}

.loyalty-card-list-item-container .stamp-container .stamp-label {
    font-size: 8.05px;
    margin-left: 4px;
}

.loyalty-card-list-item-container .stamp-container .distance {
    font-size: 9.39px;
    margin-right: 12px;
    margin-left: auto;
}


.loyalty-card-detail-view-container .loyalty-card-detail-view { 
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    padding: 10px;
    padding-bottom: 0;
    font-family: 'Helvetica-preview';
    margin-top: 5.76px;
}

.loyalty-card-detail-view-container .list-part {
    display: flex;
    height: 60.5px;
}

.loyalty-card-detail-view-container .icon-container {
    display: flex;
    height: 37.76px;
    align-items: center;
    padding-left: 11.5px;
    padding-right: 10px;
    justify-content: space-between;
}

.icon-container .right {
    display: flex;
    justify-content: space-between;
    width: 37.44px;
}

.loyalty-card-detail-view-container .card-logo {
    height: 35px;
    width: 35px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.coupon-detail-view-container .card-logo {
    height: 45px;
    width: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0 0 auto;
}

.partner-main-description .card-logo {
    margin: 16.5px auto 0 auto;
    height: 48px;
    width: 48px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.card-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.loyalty-card-detail-view-container .promo-name-container {
    margin-left: 7.7px;
    width: 143.5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 6.15px;
}

.loyalty-card-detail-view-container .promo-name {
    font-size: 9px;
    font-family: 'Helvetica-light-preview';
    margin-top: 3px;
}

.loyalty-card-detail-view-container .comp-name-promo {
    font-size: 10.25px;
    font-family: 'Helvetica-bold-preview';
}

.loyalty-card-detail-view-container .stamp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 33px;
    margin-right: 0;
    margin-left: auto;
}

.loyalty-card-detail-view-container .stamp-container .stamp-numbers {
    font-size: 12.8px;
    font-family: 'Helvetica-bold-preview';
    margin-top: 2px;
}

.loyalty-card-detail-view-container .stamp-container .stamp-label {
    font-size: 9px;
    font-family: 'Helvetica-light-preview';
}

.loyalty-card-detail-view-container .stamp-img-container {
    display: flex;
    flex-wrap: wrap;
    padding-left: 2px;
}

.stamp-img-container .stamp-img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin: 0 7.7px 15.4px 7.7px;
}

.stamp-img-container .stamp-icon {
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.stamp-img-container .reward {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100%;
    margin-top: -90%;
}

.stamp-img-container .reward svg {
    height: 10px;
    width: 10px;
}

.loyalty-card-detail-view-container .stamping-label-container,
.coupon-detail-view-container .stamping-label-container {
    height: 32px;
    position: relative;
}

.stamping-label-container .stamping-label {
    width: 220px;
    height: 32px;
    background-color: rgb(0, 174, 239);
    border-radius: 25.5px;
    font-family: 'Helvetica-bold-preview';
    color: #FFF;
    font-size: 12.8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loyalty-card-detail-view-container .stamping-label {
    position: absolute;
    top: 16px;
}

.coupon-detail-view-container .stamping-label {
    margin: 16px auto;
}

.loyalty-card-detail-text, .coupon-detail-text, .partner-detail-text {
    background-color: #FFF;
    color: #18181e;
}

.loyalty-card-detail-text .detail-text-container,
.coupon-detail-text .detail-text-container,
.partner-detail-text .detail-text-container {
    padding: 13.3px 18px 13.1px 11.4px;
    border-bottom: 1px solid #E4E4E4;
}

.detail-text-container .icon-title-container {
    display: flex;
    align-items: center;
}

.detail-text-container img, .detail-text-container svg {
    opacity: 0.6;
}

.detail-text-container .icon-description-container {
    display: flex;
}

.detail-text-container .icon-one-line-description-container {
    display: flex;
    align-items: center;
}

.icon-title-container .title {
    width: 178.3px;
    margin-left: auto;
    font-family: 'Helvetica-bold-preview';
    font-size: 9.5px;
    line-height: 11px;
}

.detail-text-container .description {
    width: 178.3px;
    margin-top: 4.6px;
    margin-left: auto;
    font-family: 'Helvetica-preview';
    font-size: 9px;
    line-height: 12.3px;
}

.icon-description-container .description,
.icon-one-line-description-container .description{
    margin-top: 0;
}

.detail-text-container .description .bold {
    font-family: 'Helvetica-bold-preview';
}

.detail-text-container .description .redeem-id {
    color: rgb(0, 174, 239);
}

.description .navigation-container {
    display: flex;
}

.detail-text-container .hidden-text {
    color: #DFDFDF;
    background-color: #DFDFDF;
    height: 10px;
    margin-bottom: 10px;
}

.detail-text-container .hidden-text-last-line {
    margin-bottom: 0px;
    width: 70%;
}

.navigation-container .distance {
    background-color: rgb(229, 232, 235);
    border-radius: 4px;
    padding: 0 5.1px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16.6px;
    box-sizing: border-box;
}

.navigation-container .navigate {
    background-color: rgb(0, 174, 239);
    color: #FFF;
    border-radius: 4px;
    padding: 0 5.1px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16.6px;
    box-sizing: border-box;
    margin-left: 4px;
}

.coupon-list-item-container {
    height: 114px;
    width: 230px;
    margin: 0 auto;
    border-radius: 8px; 
    box-sizing: border-box;
    padding: 8px 8px 13.41px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Helvetica-preview';
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

}

.coupon-list-item-container .upper-part-container {
    height: 21.46px;
    display: flex;
    justify-content: space-between;
}

.upper-part-container svg {
    margin-top: 4.5px;
}
.coupon-list-item-container .discount-container {
    display: flex;
    flex-direction: column;
}

.discount-logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.discount-logo-container .discount-label {
    font-family: 'Helvetica-Light-preview';
    font-size: 11px;
    margin-left: 5px;
}


.coupon-list-item-container .discount-value-container {
    padding: 0 8.8px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 21.45px;
    max-height: 21.45px;
    box-sizing: border-box;
    background-color: rgb(252,183,47);
    width: fit-content;
    align-self: flex-end;
}

.coupon-detail-view .discount-value-container {
    padding: 0 9px;
    border-radius: 20.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 27px;
    max-height: 27px;
    box-sizing: border-box;
    background-color: rgb(252,183,47);
    width: fit-content;
}

.discount-value-container .discount-value {
    font-family: 'Helvetica-bold-preview';
    font-size: 12.3px;
    color: rgb(24,24,30);
}

.coupon-detail-view .discount-value-container .discount-value {
    font-size: 18px;
}

.discount-container .discount-label {
    font-size: 8px;
    color: #FFF;
    align-self: center;
    margin-top: 2px;
}

.discount-container .gift-icon-container,
.discount-logo-container .gift-icon-container {
    display: flex;
    align-items: center;
}

.discount-value-label-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 170px;
}

.promo-name-container .distance-promo-container {
    font-size: 9.37px;
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
    color: white;
}

.coupon-list-item-container .comp-name-promo {
    font-size: 12px;
    font-family: 'Helvetica-bold-preview';
    color: #FFF;
}

.coupon-list-item-container .promo-name-container {
    display: flex;
    flex-direction: column;
}

.coupon-detail-view-container,
.partner-detail-view-container,
.loyalty-card-detail-view-container {
    /*background-color: black;
    border: 3px solid #000;
    box-sizing: border-box;*/
    width: 240px;
    margin: 0 auto;
    margin-top: -3.5px;
}

.loyalty-card-detail-view-container {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.coupon-detail-view-container .content,
.partner-detail-view-container .content,
.loyalty-card-detail-view-container .content {
    width: 240px; 
    margin: 0 auto;
}

.coupon-detail-view-container .icon-container,
.partner-detail-view-container .icon-container {
    display: flex;
    justify-content: space-between;
    padding: 24px 8.4px 0 8.4px;
}

.coupon-detail-view-container .coupon-background,
.partner-detail-view-container .partner-background {
    width: 240px;
    height: 192px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.coupon-detail-view-container .icon-container img,
.partner-detail-view-container .icon-container img {
    height: 24.35px;
    width: 24.35px;
}

.coupon-detail-view-container .coupon-detail-view,
.partner-detail-view-container .partner-detail-view {
    background-color: #FFF;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin-top: -33px;
    box-sizing: border-box;
    padding-top: 5px;
    color: #18181e;
}

.coupon-detail-view-container .coupon-main-description,
.partner-detail-view-container .partner-main-description {
    padding: 0 10.24px;
}

.coupon-detail-view .discount-label {
    margin-top: 4px;
    margin-bottom: 4px;
}

.coupon-main-description .gray-div,
.partner-main-description .gray-div {
    width: 32px;
    height: 4px;
    margin: 0 auto;
    margin-bottom: 7px;
    background-color: #d0d0d0
}

.coupon-main-description .comp-name-promo,
.partner-main-description .comp-name-promo {
    margin-top: 19.2px;
    margin-bottom: 3px;
    font-family: 'Helvetica-bold-preview';
    font-size: 12.8px;
}

.partner-main-description .comp-name-promo {
    text-align: center;
}

.partner-main-description .description {
    font-size: 9.6px;
    line-height: 12.8px;
    text-align: center;
    margin-top: 6.2px;
    font-family: 'Helvetica-light-preview';
}

.partner-main-description .coupon-and-card-container {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
}

.coupon-and-card-container .icon-and-label-container {
    width: 106px;
    height: 60px;
    border-radius: 6.4px;
    box-sizing: border-box;
    border: 1px solid #e5e8eb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 14px;
}

.icon-and-label-container .label {
    font-size: 9.5px;
    font-family: 'Helvetica-bold-preview';
    margin-top: 6.4px;
}

.coupon-main-description .promo-name {
    margin-bottom: 19.2px;
    font-family: 'Helvetica-light-preview';
    font-size: 10.25px;
}

.coupon-main-description .discount-description {
    margin-top: 19.2px;
    font-family: 'Helvetica-light-preview';
    font-size: 9.6px;
    line-height: 12.8px;
}

.partner-list-item-container {
    width: 240px;
    font-family: 'Helvetica-preview';
    color: #18181e;
    margin: 0 auto;
}

.partner-list-item-container .our-partners-label {
    font-family: 'Helvetica-bold-preview';
}

.partner-list-item-container .icon-container,
.list-view-background .icon-container {
    display: flex;
    background-color: #FFF;
    justify-content: space-between;
    align-items: center;
    padding: 18px 10.5px 9.3px 10.5px;
    box-sizing: border-box;
    height: 42.5px;
}

.list-view-background .cathegory-container {
    display: flex;
    background-color: #FFF;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 9px 10.5px;
    box-sizing: border-box;
    height: 36.5px;
    overflow: hidden;
}

.cathegory-container .cathegory {
    padding: 5px 9px 5px 8px;
    border-radius: 20px;
    border: 1px solid #18181e;
    display: flex;
    min-height: 22.5px;
    max-height: 22.5px;
    box-sizing: border-box;
    white-space: nowrap;
    margin-right: 5px;
    opacity: 0.5;
}

.cathegory-container .cathegory-all {
    border: none;
    background-color: #2baeef;
    font-size: 10px;
    color: #FFF;
    opacity: unset;
}

.cathegory img {
    height: 10px;
}

.cathegory .name {
    margin-left: 4px;
    font-size: 10px;
}

.partner-list-item-container .header-label,
.list-view-background .header-label {
    font-family: 'Helvetica-bold-preview';
    font-size: 15.5px;
}

.icon-container img {
    height: 14px;
    width: 14px;
}

.partner-list-item-container .gray-div {
    height: 25.5px;
    font-family: 'Helvetica-bold-preview';
    padding-left: 10.5px;
    font-size: 10.25px;
    opacity: 0.8;
    display: flex;
    align-items: center;
    border-top: 1px solid #e5e8eb;
    border-bottom: 0.5px solid #e5e8eb;
}

.partner-list-item-container .logo-promo-container {
    height: 54px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    padding: 0 10.5px;
    border-bottom: 0.5px solid #e5e8eb;
}

.logo-promo-container .comp-logo {
    height: 28px;
    width: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 8px;
}

.logo-promo-container .comp-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logo-promo-container .promo-name {
    font-size: 9.5px;
    font-family: 'Helvetica-bold-preview';
}

.container .mobile-preview {
    display: table;
    position:relative;
    z-index: 5;
    width: auto;
    border-style: solid;
    border-width: 61px 16px 413px 16px;
    background-color: #f2f3f4;
    border-radius: 40px;
    -moz-border-image: url(/imgs/mobileBorder.png) 61 16 413 16 stretch repeat;
    -webkit-border-image: url(/imgs/mobileBorder.png) 61 16 413 16 stretch repeat;
    -o-border-image: url(/imgs/mobileBorder.png) 61 16 413 16 stretch repeat;
    border-image: url(/imgs/mobileBorder.png) 61 16 413 16 stretch repeat;
}

.content .detail-preview_map-placeholder {
    background-image: url(/imgs/placeholder_map_icon.svg);
    background-size: 50px;
    background-color: #E5E8EB;
    width: 240px;
    height: 148px;
    background-position: center center;
    background-repeat: no-repeat;
}

.google-maps-placeholder {
    background-image: url(/imgs/placeholder_map_icon.svg);
    background-size: 80px;
    background-color: #E5E8EB;
    width: 432px;
    height: 265px;
    background-position: center center;
    background-repeat: no-repeat;
}

.placeholder {
    background-color: #E5E8EB;
    background-position: center center;
    background-repeat: no-repeat;
}

.company-logo-placeholder {
    background-image: url(/imgs/bottom_navigation_partners_icon.svg);
    width: 100%;
    height: 100%;
}

.yalty-logo-placeholder {
    background-image: url(/imgs/yalty_logo_white.svg);
    width: 185px;
    height: 110px;
    background-size: 70px;
}

.placeholder-y-icon {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 5px;
}

.placeholder-y-icon.stamped {
    background-color: #00AEEF;
}

.placeholder-y-icon img {
    width: 9px;
    height: 16px;
}

.placeholder-y-icon.unstamped {
    background-color: #E5E8EB;
}

.card-logo .company-logo-placeholder,
.comp-logo .company-logo-placeholder {
    background-size: 60%;
    border-radius: 50%;
}

.previewContainer .company-logo-placeholder {
    background-size: 45%;
}

.description.not-given {
    font-style: italic;
    color: #8B8B8E;
}

.stampOrRedeemHappenedEverErrorMessage {
    display: flex; 
    width: 100%; 
    max-width: 620px;
    margin: auto 10px auto 0;
    padding: 5px 10px;
    box-sizing: border-box;
}
.stampOrRedeemHappenedEverErrorMessage img {
    margin: auto 10px auto 0;
}
.stampOrRedeemHappenedEverErrorMessage p {
    margin: auto 10px auto 0;
}
.sampleViewOfDashboardOrTable {
    max-width: 95%;
    border-radius: 10px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    cursor: pointer; /* Mutatja, hogy kattintható */
}

.fullscreen-model {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Sötét áttetsző háttér */
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.fullscreen-model img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px; /* Ugyanúgy lekerekítve */
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Árnyék */
    animation: fadeIn 0.3s ease-in-out;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

@media (max-width:1365px){
    html,body{
        background-color: white;
    }
    .container .content, .container .yalty_top .content{
        position: relative;
        width: 100%;
    }
    .container .contentYaltyAdmin, .container .yalty_top .contentYaltyAdmin{
        position: relative;
        width: 100%;
    }
    .container .content .aside{
        margin-left:0;
        /*width: 15%;*/
    }
    .container .contentYaltyAdmin .asideYaltyAdmin{
        margin-left:0;
        /*width: 15%;*/
    }
    .container .content .main{
        /*width: 85%;*/
        overflow-x: scroll;
    }
    .container .contentYaltyAdmin .main{
        /*width: 85%;*/
        overflow-x: scroll;
    }
    .yalty-animate-left{
        position:relative;
        animation:animateleft 0.4s
    }

    @keyframes animateleft{
        from{
            left:-350px;opacity:0
        }to{
            left:0;opacity:1
        }
    }
}
@media (max-width:1455px){
    #searchbox {
        width: 100%;
    }
    #quickSearchText {
        width: 75%;
        margin-left: 5%;
    }
    #searchButtonContainer {
        margin-left: 5%;
    }
}

@media (max-width:1340px){
    #searchButtonContainer {
        margin-left: 2%;
    }
}

@media (max-width:1400px){
    .container .contentYaltyAdmin,.container .yalty_top .contentYaltyAdmin{
        width: 100%;
    }
    .container .contentYaltyAdmin .asideYaltyAdmin{
        display:none;
        width: 0;
    }
    .container .contentYaltyAdmin .main{
        width: 100%;
    }
    .container .contentYaltyAdmin .asideYaltyAdmin .yalty-sidebar{width: 205px;}
    .container #openMenuYaltyAdmin{
        display:block;
        z-index: 12;
    }
    .container #closeMenuYaltyAdmin,.container .fa-barYaltyAdmin{
        display: block;
    }
}

@media (max-width:1120px){
    .container .content,.container .yalty_top .content{
        width: 100%;
    }
    .container .content .aside{
        display:none;
        width: 0;
    }
    .container .content .main{
        width: 100%;
    }
    .container .content .aside .yalty-sidebar{width: 30%;}
    .container #openMenu{
        display:block;
        z-index: 12;
    }
    .container #closeMenu,.container .fa-bar{
        display: block;
    }
}
@media (max-width:960px){
    md-card.subscriptionCard .price-button {
        width: 140px;
        padding: 5px 10px;
    }
    .container .preregLandingList{ margin-top: 50px;margin-left: 0; }
    .container .preregLandingList li > div{width: 100%;}
    .container .subscriptionPage .lineHeight { height: 4em;}
    .subscriptionWidth {
        min-width: 250px;
    }
}

@media (max-width:850px){
    .container #user-detail-style{max-width: 200px;}
}

@media (max-width:675px), (max-width:675px) and (orientation:landscape){
    #menuForCashing{
        display: none;
    }
}
@media (min-width:675px), (min-width:675px) and (orientation:landscape){
    #menuForCashing2{
        display: none;
    }
}
@media (max-width:650px), (max-width:650px) and (orientation:landscape){
    .container #sloganText{display:none}
}
@media (min-width:600px), (min-width:600px) and (orientation:landscape){
    #dropDownContentInTop{
        margin-top: 64px;
    }
}
@media (max-width:600px), (max-width:600px) and (orientation:landscape){
    #dropDownContentInTop{
        margin-top: 0px;
    }
    .welcomeScreenImagesDiv{
        width: 300px;
        background-color: #e6f7fd; 
        border-radius: 10px;
        padding: 10px; 
    }
    .welcomeScreenDownloadDiv1{
        display: none;
    }
    .welcomeScreenDownloadDiv2{
        display: flex;
    }
    .welcomeScreenCenterDiv{
        margin-top: 25px;
    }
    .welcomeScreenImages2 {
        display: none;
    }
    .loyaltyCardYaltyScreenImagesDiv{
        width: 350px;
        background-color: #e6f7fd; 
        border-radius: 10px;
        padding-left: 0px; 
        padding-bottom: 20px;
        margin: auto; 
    }    
    .container .main{
        width: calc(100% - 206px);
        float: left;
        height: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        overflow: hidden;
        overflow-y: auto;
        background-color: white;
    }
    .subscriptionWidth {
        min-width: 180px;
    }
    .subscriptionLabel {
        width: 120px;
    }
    .subscriptionLabel2 {
        width: 120px;
    }
    .landingContainer {
        width: 640px;
        padding-left: 0px;
    }
    .container .content .aside{display:none}
    .container .contentYaltyAdmin .asideYaltyAdmin{display:none}
    .container .content .main{width: 100%;}
    .container .contentYaltyAdmin .main{width: 100%;}
    .container .redeemContainer {width: 100%;}
    .container .redeemContainer .redeemStatusPanel p {text-align: left;margin-top: 0;}
    .container .redeemContainer .createRedeemButton {
        float: unset;
    }
    .tooltip .yaltytiptextleft {width: 110px;}
    .container #createRedeemArea.cropArea {top: 55%; overflow: auto;}
    .container .redeemContainer .createRedeemWindow {width: 95%;height: 90%;}
    .container .redeemContainer .YLCSelectedProductWindow {width: 95%;}
    .ap-otp-input {width: 26px; height: 28px; border-radius: 9px; font-size: 18px; margin-left: 2px; margin-right: 2px; margin-top: 4px; margin-bottom: 4px;}
    #publicUserId3{
        margin-right: 12px;
    }
    .container .redeemStatusPanel {width: 100%;}
    .container .createRedeemFormLargeElement {width: 45%;}
    .container md-card{width: 100%;}
    .container .redeemName {font-size: 100%;}
    .container .cashingLoyaltyCard, .container .cashingCouponCard{width:100%;max-width: 500px;}
    .container #stampListContainer, .container .rejectCashingButtonContainer{width: 100%;padding-top: 0px;}
    .container .stampIncreaseContainer{width: 240px;}
    .container .finalize-button, .container .finalize-button.md-button:not([disabled]):hover {max-width: 150px; min-width: 115px; width: unset;}
    .container .finalize-button-reject, .container .finalize-button-reject.md-button:not([disabled]):hover {max-width: 150px; min-width: 115px; width: unset;}
    .container .cashingDiscountMargin{margin-bottom:0px;}
    .container #yalty-slogan{margin-left:10px;}
    .container #user-detail-style{margin-right:5px;}
    .container #timer{font-size:110%;}
    /*.container .yalty-sidebar .yalty-bar-item{width: unset;}*/
    .container .optionsMenu{margin-right:3px;}
    .container .content .aside .yalty-sidebar{width: 60%;}
    .container .contentYaltyAdmin .asideYaltyAdmin .yalty-sidebar{width: 205px;}
    .container .yalty-bar-item{text-align:center}
    .container .yalty-button{width:100%}
    .container .cashingInfo{display: none;}
    .container .mobileCashingStatus{display: block;}
    .container .cashingStatus{display: none;}
    .container .priceButtonContainer { padding: 10px; }
    .container .borderDetailMobile { margin: auto; }
    .stampLayout { text-align: -webkit-center}
}
@media (max-width:400px){
    .container .redeemStatusDetailContainer {margin:auto;display: block;}
    .container .finalizeTextContainer {width: 50%;}
    .container .redeemName {font-size: 90%;}
    .container .finalizeButtonContainer {width: 50%;}
    .container #user-detail-style{display: none;}
}

@media (max-width: 340px) {
    .container .dropdown {
        min-width: 120px;
    }
}

@media (max-height: 300px) {
    .container #createRedeemArea.cropArea{top: 65%;height: 30%;}
}