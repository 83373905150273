body{
  font-family: Helvetica, sans-serif
}

/*@media (max-width:800px) {
  .yalty-top {
    display: none;
  }
}*/

@font-face {
  font-family: 'Verdana-Bold';
  src: url('fonts/Verdana-Bold.eot');
  src: url('fonts/Verdana-Bold.eot?#iefix') format('embedded-opentype'),
      url('fonts/Verdana-Bold.woff') format('woff'),
      url('fonts/Verdana-Bold.ttf') format('truetype'),
      url('fonts/Verdana-Bold.svg#Verdana') format('svg');
  font-weight: normal;
  font-style: normal;
}


@media (max-width:550px) {
  .appStore, .playStore {
    display: none;
  }
  #nyitokep {
    transform: translate(-50%, 0);
    left: 50%;
    position: absolute; 
    bottom: 0; 
    width: 95%; 
    height: auto; 
  }
}

@media (min-width:551px) {
  #nyitokep {
    position: absolute; 
    bottom: 0; 
    min-width: 38%; 
    max-width: 300px; 
    height: auto; 
    padding-left: 80px; 
    padding-right: 20px;
  }
}

.currentPageTitle {
  font-size:250%;
  color:white;
}
@supports (-moz-transform: translate(0, 0)) {
  .currentPageTitle {
    font-size:250%;
    color:white;
  }
}
@supports (-ms-ime-align:auto) {
  .currentPageTitle {
    font-size:250%;
    color:white;
  }
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.partnerHomeText {
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.partnerHomeText2 {
  font-size: 26px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.partnerHomeText3 {
  font-size: 26px;
}

.partnerHomeText4 {
  font-size: 22px;
  max-width: 910px;
  margin-left: auto; 
  margin-right: auto;
}

#contactText a:link {
  color: #000;
  background-color: transparent;
}

#contactText a:visited {
  color: #000;
  background-color: transparent;
}

#contactText a:hover {
  color: #000;
  background-color: transparent;
}

#contactText a:active {
  color: #000;
  background-color: transparent;
}

.scroller {
  max-width: 100vw;
}

.scroller_inner {
  padding-block: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  /*-webkit-mask: linear-gradient(90deg, transparent, white 2.5%, white 97.5%, transparent);
  mask: linear-gradient(90deg, transparent, white 2.5%, white 97.5%, transparent);*/
}

.scroller[data-animated="true"] .scroller_inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: 
    scroll 
    var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) 
    linear 
    infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 28s;
}

.scroller[data-speed="medium"] {
  --_animation-duration: 37s;
}


.scroller[data-speed="slow"] {
  --_animation-duration: 55s;
}


@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}


.mobileBannerHU, .mobileBannerEN {
  width:100%;
  z-index:15;
  margin-left: 0px;
  background-color: white;
  position:fixed;
}
.mobileBannerHU .mobileBannerClickable{
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-image: url(/imgs/yalty_public_web_app_download_HU.png);
  background-repeat: no-repeat;
  width: 1020px;
  height: 240px;
}
.mobileBannerEN .mobileBannerClickable{
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-image: url(/imgs/yalty_public_web_app_download_EN.png);
  background-repeat: no-repeat;
  width: 1020px;
  height: 240px;
}
.mobileBannerHU .mobileBannerClickable #closeMobileBanner, .mobileBannerEN .mobileBannerClickable #closeMobileBanner{
  width: 70px;
  height: 240px;
  outline: none;
}
.mobileBannerHU .mobileBannerClickable #navigateToDownload, .mobileBannerEN .mobileBannerClickable #navigateToDownload{
  width: 950px;
  height: 240px;
  margin-left: 70px;
  margin-top: -240px;
  outline: none;
}
.yalty-top{
    width:100%;
    z-index:10;
    margin-left: 0px;
    position:fixed;
    color:white;
}
.yalty-top p {
  padding: 0;
}
.cookie_policy{
  background:rgba(0,0,0,.8);
  position: fixed; 
  bottom: 0; 
  left: 0; 
  right: 0; 
  text-align: center;
}

/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */    
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 12;
  cursor: pointer;
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: hidden; /* Disable vertical scroll */
  overflow: hidden;
}

.loadingoverlay{
  color: white;
  font-size: 15px;
  font-weight: bold;
  position:fixed;
  display:none;
  width:100%;
  height:100%;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:rgba(0,0,0,0.8);
  z-index:20;
  cursor: pointer;
text-align: center;
}
.loadingoverlay img{
  display: block;
    margin-left: auto;
    margin-right: auto;
}
.cookie_policy .acceptPolicyButton{
  background-color: #ed7d31;
  min-height: 24px;
  line-height: 20px;
  padding: 5px;
  color:white;
  opacity: 1; 
  margin-left: -100px;

}
.cookie_policy .acceptPolicyButton:hover{
  background-color: #ed7d31;
}
.yaltyPartnerStyle{
  float: right;
  font-family: 'Verdana-Bold';
  font-size:150%;
  color:white;
  margin-top:5px;
  margin-right: 1vw;
  margin-bottom:0px;
  padding:10px;
}
.yaltyStyle{
    height: 39px;
    float: left;
    margin-right: 10px;
    margin-left: 40px;
    margin-top: 0px;
}
.sloganStyle{
  float: left;
  font-size:16px;
  color:#404040;
  padding-top:15px;
}
.yalty-button{
    border:none;
    display:inline-block;
    outline:0;
    padding:15px 5px;
    vertical-align:middle;
    overflow:hidden;
    text-decoration:none;
    color:white;
    background-color:inherit;
    cursor:pointer;
    white-space:nowrap;
    font-size: 90%;
}
#fogyaszto{
  text-align: left;
}
.container_upper{
  height:90vh;
  width: 100%;
  display:flex;
  align-items:center;
  justify-content:center;
  vertical-align: middle;
}
.container_upper2{
  height:auto;
  width: 100%;
  display: flex;
  align-items:top;


}
.sectionpubliccouponview{
  overflow: hidden;
  position: static;
  background-color: #00AEEF;
  padding: 0;
  height: auto;
  padding-bottom: 100px;
  min-height: 100vh;

}
.signup_upper{
  width: 100%;
  display:flex;
  align-items:center;
  justify-content:center;
}

.container_lower{
  height:20vh;
  width: 100%;
  margin-top: -10vh;
  display:flex;
  align-items:center;
  justify-content:center;
}
.appStore, .playStore {
  box-sizing: border-box;
  float:left;
  width:150px;
  padding: 10px;
  margin: 0px 10px;
}
.clickBorder{
  width: 180px;
  height: 40px;
  z-index: 6;
  position: absolute;
  top: 50%;
  margin-left: 500px;
  margin-top:230px;
  transform: translate(0, -50%);
}
.clickBorder div{
  outline: none;
  border: 0;
}
.borderMobile table, .borderMobile table tbody, .borderMobile table tbody tr, .borderMobile table tbody tr td{
  border-spacing: 0px;
  padding: 0;
  margin: 0;
  max-height: 182px;
  max-width: 326px;
}
.borderMobile{
  z-index: 5;
  position: absolute;
  top:50%;
  transform: translate(0, -50%);
  text-align: center;
  max-width: 200px;
  max-height: 400px;
  border-style: solid;
  border-width: 40px 10px 40px 10px;
  border-radius: 30px;
  -moz-border-image: url(/imgs/mobileBorder.png) 61 10 62 10 fill ;
  -webkit-border-image: url(/imgs/mobileBorder.png) 61 10 62 10 fill ;
  -o-border-image: url(/imgs/mobileBorder.png) 61 10 62 10 fill ;
  border-image: url(/imgs/mobileBorder.png) 61 10 62 10 fill ;
}
.yaltyImg{
  position: absolute;
  max-width: 550px;
  top: 50%;
  transform: translate(-75%, -50%);
}
.yaltyDiscountInfo{
  position: absolute;
  max-width: 400px;
  line-height: 35px;
  font-size: 120%;
  top: 50%;
  transform: translate(50%, -50%);
}
.yaltyDiscountCategoryBox{
	background-color: #FFFFFF;
  box-shadow: 1px 1px 1px #4E525C;
  position: absolute;
  width: 230px;
  min-height: 500px;
  height: auto;
  top: 15%;
  right: 70%;
}
.yaltyDiscountCategoryBoxVarosom{
margin-top: 10px;
margin-bottom: 20px;
text-align: center;
}
.yaltyDiscountCategoryBoxVarosom select[name="varosok"]{
  color: #000000;
  background: transparent;
  outline: none;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  height: 25px;
  width: 80%;
  max-height: 25px;
  padding: 0px;
  margin-top: 8px;
  font-size: 15px;
  border-bottom: 1px solid black;
  }
.yaltyDiscountCategoryBoxVarosom input[type=text]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
  }
  .yaltyDiscountCategoryBoxVarosom input[type=text] { /* Internet Explorer 10-11 */
    color: black;
  }
  
  .yaltyDiscountCategoryBoxVarosom input[type=text] { /* Microsoft Edge */
    color: black;
  }
  .yaltyDiscountCategoryBoxListNameItem{
    width: 70%;
    padding: 5px;
    margin-top: 5px;
    margin-left: 2px;
    margin-bottom: 5px;
    font-size: 15px;
    text-align: right;
    cursor: pointer;
  }
  .yaltyDiscountCategoryBoxListNameItem2{
    width: 70%;
    padding: 0px;
    margin-top: 5px;
    margin-left: 2px;
    margin-bottom: 5px;
    font-size: 15px;
    text-align: left;
    cursor: pointer;
  }
  .yaltyDiscountCategoryBoxListNameItem:focus {
    outline: 0;
    
  }
  .yaltyDiscountCategoryBoxListNameItemImg img{
    position: absolute;
    left: 80%;
    margin-top: -30px;
    width: 20px;
    height: 20px;
  }
  .yaltyDiscountCategoryBoxListNameItem:hover{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 2px;
    font-size: 15px;
    color: #FFFFFF;
    background-color: #00AEEF;
  }
  .yaltyDiscountCategoryBoxListNameItemSelected{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 2px;
    background-position: 25% 75%;
    background-repeat:no-repeat;
    font-size: 15px;
    background-color: #00AEEF;
    color: white;
    outline: none;
  }
  .yaltyDiscountCategoryBoxListNameItemSelected:focus {outline: 0;}

  .yaltyDiscountCategoryBoxListPicItem{
    width: 30%;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    text-align: left;
  }
.yaltyDiscount{
top: 15%;
right: 68%;
position: absolute;
}
.yaltyDiscountSearch{
  color: #FFFFFF;
  position: absolute;
  width: 640px;
  line-height: 35px;
  font-size: 100%;
  border-bottom: 1px solid white;
  margin-bottom: 10px;
}
.yaltyDiscountSearch input[type=text] {
  color: #FFFFFF;
  background: transparent;
  outline: none;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 615px;
  height: 25px;
  font-size: 17px;
}
.yaltyDiscountSearch input[type=text]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.yaltyDiscountSearch input[type=text]::-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

.yaltyDiscountSearch input[type=text]::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
}

.yaltyDiscountSearch button {
  float: right;
  padding: 2px;
  background: transparent;
  color: white;
  font-size: 17px;
  border: 0px;
  border-left: none;
  cursor: pointer;
}
.yaltyDiscountContainer{
  width: 650px;
  position: absolute;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;
  grid-row-gap: 30px;

 margin-top: 60px;
}
.yaltyDiscountContaineritem{
  position: relative;
  width: 300px;
  height: 180px;
  background-color: white;
  box-shadow: 1px 1px 1px #4E525C;
  cursor: pointer;
}
.yaltyDiscountContaineritem img{
width: 300px;
height: 120px;
}
.yaltyDiscountContaineritemszolgaltato{
  margin-left: 5px;
position: static;
color: #666666;
font-size: 10px;
font-weight: bold;
text-transform: uppercase;
margin-top: 2px;
}
.yaltyDiscountContaineritemcim{
  margin-left: 5px;
position: static;
color: #000000;
font-size: 10px;
font-weight: bold;
text-transform: uppercase;
margin-bottom: 2px;
}
.kuponnezetbox1{
  position: absolute;
  width: 70%;
  height: 50px;
}
.kuponnezetbox2{
  position: absolute;
  margin-left: 70%;
  width: 30%;
  height: 50px;
}
.yaltyDiscountContaineritemkedvezmenyszazalek{
  position: static;
  color: #ed7d31;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}
.yaltyDiscountContaineritemkedvezmenyszazaleksmall{
  position: static;
  color: #ed7d31;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
.yaltyDiscountContaineritemkedvezmenyajandek{
  position: static;
  color: #ed7d31;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.yaltyDiscountContaineritemkedvezmenyajandeksmall{
  position: static;
  color: #ed7d31;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.yaltyDiscountContaineritemkedvezmenyajandeksmallsmall{
  position: static;
  color: #ed7d31;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}
.yaltyDiscountContaineritemkedvezmenyar{
  position: static;
  color: #ed7d31;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  }
  .yaltyDiscountContaineritemkedvezmenyarsmall{
    position: static;
    color: #ed7d31;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    }
  .yaltyDiscountContaineritemegyedikedvezmeny{
    position: static;
    color: #ed7d31;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    padding: 5px;
    }
    .yaltyDiscountContaineritemegyedikedvezmenysmall{
      position: static;
      color: #ed7d31;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      margin: auto;
      padding: 5px;
      margin-top: 10px;
      }
      .yaltyDiscountContaineritemegyedikedvezmenysmallsmall{
        position: static;
        color: #ed7d31;
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        padding: 5px;
        margin-top: 10px;
        }
  .yaltyDiscountContaineritemkedvezmenyregiar{
    position: static;
    color: #000000;
    padding-top: 2px;
    text-align: left;
    padding-left: 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    text-decoration: line-through;
    }
    .yaltyDiscountContaineritemkedvezmenynotshow{
      display: none;
    }
    .yaltyDiscountContaineritemkedvezmenyujar{
      position: static;
      color: #ed7d31;
      text-align: center;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 5px;
      margin-right: 5px;
      padding-top: 5px;
      }
      .yaltyDiscountContaineritemkedvezmenyujar2{
        position: static;
        color: #ed7d31;
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
        margin-right: 5px;
        padding-top: 5px;
        }
        .yaltyDiscountContaineritemkedvezmenyujarsmall{
          position: static;
          color: #ed7d31;
          text-align: center;
          text-transform: uppercase;
          font-size: 13px;
          font-weight: bold;
          margin-bottom: 5px;
          margin-right: 5px;
          padding-top: 10px;
          }
          .yaltyDiscountContaineritemkedvezmenyujar2small{
            position: static;
            color: #ed7d31;
            text-align: center;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 5px;
            margin-right: 5px;
            padding-top: 10px;
            }
.yaltyDiscountContaineritemkedvezmenytext{
text-align: center;
vertical-align: center;
position: static;
color: #000000;
padding-top: 5px;
font-size: 8px;
font-weight: bold;
text-transform: uppercase;
margin-right: 5px;
margin-bottom: 5px;
}

.yaltyShowCouponDetailsnyom{
    background-color: #FFFFFF;
    box-shadow: 1px 1px 1px #4E525C;
    position: relative;
    width: 595px;
    height: auto;
    z-index: 400;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .yaltyShowCouponDetailssnyom img{
    width: 280px;
    height: 170px;
  }
  
  .yaltyShowCouponDetailsleirasnyom{
  margin: 5px;
  text-align: center;
  position: relative;
  color: #000000;
  /*top: 180px;*/
  font-size: 14px;
  font-weight: solid;
  text-align: justify;
  }
  .yaltyShowCouponDetailsSzolgaltatoLogonyom{
    background-color: #FFFFFF;
    box-shadow: 1px 1px 1px #4E525C;
    width: 150px;
    height: 60px;
    margin-left: 610px;
    z-index: 400;
    position: absolute;
    text-align: center;
    }
    .yaltyShowCouponDetailsSzolgaltatoLogonyom img{
        max-width:150px;
        max-height:60px;
        width: auto;
        height: auto;
    }


.yaltyShowCouponDetails{
  background-color: #FFFFFF;
  box-shadow: 1px 1px 1px #4E525C;
  position: fixed;
  width: 600px;
  height: 500px;
  top: 50%;
  left: 50%;
  margin-left:-300px;
  margin-top:-250px;
  z-index: 400;

  overflow-y: scroll;
  overflow-x: hidden;
}

/*.yaltyShowCouponDetails::-webkit-scrollbar{
  display: visible;
}*/


/*Scroll bar nav*/

/* Track */
.yaltyShowCouponDetails::-webkit-scrollbar-track {
  -webkit-box-shadow: auto; 
  -webkit-border-radius: auto;
  border-radius: auto;
  background:auto;    
}

/* Handle */
.yaltyShowCouponDetails::-webkit-scrollbar-thumb {
  -webkit-border-radius:auto;
  border-radius:auto;
  background:auto; 
  -webkit-box-shadow:auto; 
}
.yaltyShowCouponDetails::-webkit-scrollbar-thumb:window-inactive {
  background: auto; 
}

.yaltyShowCouponDetails img{
  width: 280px;
  height: 170px;
}
.reszletesnezetbox1{
position: absolute;
width:320px;
height: 70px;
margin-left: 280px;
margin-top: -175px;

}
.reszletesnezetbox2{
  position: relative;
  width:320px;
  height: 100px;
  margin-left: 280px;
  margin-top: -105px;
  }
  .reszletesnezetbox3{
    width: 100%;
    padding-top: 20px;
  }
.yaltyShowCouponDetailsszolgaltato{
  position: static;
  margin-left: 8px;
  margin-right: 8px;
  max-width: 90%;
  color: #666666;
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
  text-transform: uppercase;
  }
  .yaltyShowCouponDetailscim{
  margin-left: 8px;
  margin-right: 8px;
  max-width: 90%;
  position: static;
  color: #000000;
  padding-top: 5px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

  }
  .yaltyShowCouponDetailskedvezmenyszazalek{
  position: static;
  color: #ed7d31;
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  }
  .yaltyShowCouponDetailskedvezmenyar{
    position: static;
    color: #ed7d31;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: bold;
    }
    .yaltyShowCouponDetailskedvezmenyujar{
      position: static;
      color: #ed7d31;
      text-align: center;
      text-transform: uppercase;
      font-size: 30px;
      font-weight: bold;
      }
    .yaltyShowCouponDetailskedvezmenyregiar{
      position: static;
      color: #000000;
      text-align: center;
      padding-right: 50px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
      text-decoration: line-through;
      }
  .yaltyShowCouponDetailskedvezmenytext{
  text-align: center;
  margin: auto;
  position: static;
  color: #222222;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  }
  .yaltyShowCouponDetailskedvezmenytext img{
    width: 20px;
    height: 20px;
  }
.Ajandek img{
  width: 11px;
  height: 11px;

  }
.yaltyShowCouponDetailsleiras{
  margin: 5px;
text-align: center;
position: absolute;
color: #000000;
top: 180px;
font-size: 14px;
font-weight: solid;
text-align: justify;
}
.yaltyShowCouponDetailsSzolgaltatoLogo{
background-color: #FFFFFF;
box-shadow: 1px 1px 1px #4E525C;
width: 150px;
height: 60px;
margin-left: 310px;
margin-top: -250px;
z-index: 400;
position: fixed;
top: 50%;
left: 50%;
text-align: center;
}
.yaltyShowCouponDetailsSzolgaltatoLogo img{
    max-width:150px;
    max-height:60px;
    width: auto;
    height: auto;
}
.yaltyShowCouponDetailsX{
  color: #FFFFFF;
  position: fixed;
  width: 150px;
  height: 60px;
  margin-top: -275px;
  margin-left: 210px;
  z-index: 20;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 20px;
  cursor: pointer;

  }
.yaltiArSzazalek{
  display:inline;
}
.yaltyDiscountDetailsOtherMoreComp{
  position: relative;
  display:inline;
  float: left;

}
.yaltyDiscountDetailsOtherCompConteiner{
  position: relative;
  width: 500px;
  /*height: 120px;*/
  margin-right: 5px;
  margin-left: 5px;
  max-width:500px;
  min-height: 50px;
  max-height:380px;
  text-align: left;
}
.yaltyDiscountDetailsOtherCompConteiner img{

  max-width:175px;
  max-height:70px;
  width: auto;
  height: auto;
  
}
.yaltyDiscountDetailsOtherCompInfo{
  position: relative;
  top: -60px;
  left: 150px;
  font-size: 12px;

}
.yaltyDetailsAlszoveg{
position: relative;
color: #000000;
margin-top: 20px;
margin-left: 5px;
margin-right: 5px;
font-size: 13px;
font-weight: bold;
text-transform: uppercase;
text-align: left;
}
.yaltyNyitvaZarva{
  position: relative;
  top: -60px;
  left: 150px;
  font-size: 12px;
  font-weight: bold;
  color: green;
}
.yaltyTovabbiInfok{
  position: absolute;
  background: #DDDDDD;
  color: #222222;
  text-transform: uppercase;
  top: 40px;
  left: 380px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  height: auto;
  font-size: 12px;
  text-align: center;
  vertical-align: center;
  cursor: pointer;
}
.yaltyNyomtatasButton{
  position: absolute;
  text-transform: uppercase;
  width: 75px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  margin: 0 auto;
  margin-top: -175px;
  margin-left: 235px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: block;
}

.yaltyNyomtatasButton input{
  position: absolute;
  opacity: 0;
  cursor: pointer;}

  .yaltyNyomtatasButtonCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 30px;
    background-size: 60px 30px;
    background-image: url("/imgs/yalty_kupongyujto_print_unchecked.png");
}
.yaltyDiscountContaineritem:hover ~ .yaltyNyomtatasButton input ~ .yaltyNyomtatasButtonCheckmark{
  background-image: url("/imgs/yalty_kupongyujto_print_hover.png");
}
.yaltyDiscountContaineritem:hover ~ .yaltyNyomtatasButton input:checked ~ .yaltyNyomtatasButtonCheckmark{
  background-image: url("/imgs/yalty_kupongyujto_print_checked.png");
}
#yaltyNyomtatasButton:checked  .checker {
  background-position: right center;
}
.yaltyNyomtatasButtonCheckmark:hover{
  background-image: url("/imgs/yalty_kupongyujto_print_hover.png");
}
.yaltyNyomtatasButton input:checked ~ .yaltyNyomtatasButtonCheckmark {
  background-image: url("/imgs/yalty_kupongyujto_print_checked.png");
}

/* Create the checkmark/indicator (hidden when not checked) */
.yaltyNyomtatasButtonCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.yaltyNyomtatasButton input:checked ~ .yaltyNyomtatasButtonCheckmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.yaltyNyomtatasButton .yaltyNyomtatasButtonCheckmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}



/*Details* nyomtatási gomb*/
.yaltyNyomtatasButtonD{
  position: absolute;
  margin-top: -175px;
  margin-left: 650px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.yaltyNyomtatasButtonD input{
  position: relative;
  opacity: 0;
  cursor: pointer;}

  .yaltyNyomtatasButtonCheckmarkD {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 30px;
    background-size: 60px 30px;
    background-image: url("/imgs/yalty_kupongyujto_print_unchecked.png");
}

#yaltyNyomtatasButtonD:checked  .checker {
  background-position: right center;
}

.yaltyNyomtatasButtonD input:checked ~ .yaltyNyomtatasButtonCheckmarkD {
  background-image: url("/imgs/yalty_kupongyujto_print_checked.png");
}

/* Create the checkmark/indicator (hidden when not checked) */
.yaltyNyomtatasButtonCheckmarkD:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.yaltyNyomtatasButtonD input:checked ~ .yaltyNyomtatasButtonCheckmarkD:after {
  display: block;
}
/* Style the checkmark/indicator */
.yaltyNyomtatasButtonD .yaltyNyomtatasButtonCheckmarkD:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.yaltyNyomtatasButtonDetails{
  position: absolute;
  color: #FFFFFF;
  position: fixed;
  width: 150px;
  height: 60px;
  margin-top: 100px;
  margin-left: 70px;
  z-index: 20;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  display: block;
}
.yaltyNyomtatasButton2Details{
  position: absolute;
  color: black;
  text-transform: uppercase;
  background-color: white;
  position: fixed;
  width: 150px;
  height: 60px;
  margin-top: 0px;
  margin-left: 305px;
  z-index: 20;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 1px 1px 1px #4E525C;
}

.yaltyNyomtatasBox{
  position: fixed;
  color: #FFFFFF;
  text-align: center;
  vertical-align: middle;
  margin-left: 700px;
  display: block;
}
.yaltyNyomtatasBox1{
  position: relative;
  width: 120px;
  height: 60px;
  background-color: white;
  color: black;
  box-shadow: 1px 1px 1px #4E525C;
}
.yaltyNyomtatasBox2{
  position: relative;
  width: 120px;
  height: 35px;
  font-size: 12px;
  margin-top: 25px;
}
.yaltyNyomtatasBox3{
  position: relative;
  text-transform: uppercase;
  width: 120px;
  height: 30px;
  background-color: white;
  color: black;
  font-size: 13px;
  font-weight: bold;
  margin-top: 15px;
  box-shadow: 1px 1px 1px #4E525C;
  cursor: pointer;

}
.yaltyNyomtatasBox4{
  position: relative;
  text-transform: uppercase;
  width: 120px;
  height: 30px;
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 13px;
  margin-top: 15px;
  box-shadow: 1px 1px 1px #4E525C;
  vertical-align: middle;
  cursor: pointer;
}

.yaltyTovabbiak{
  position: relative;
  font-size: 12px;
  top: -20px;
}
.yaltyTovabbiak2{
  position: relative;
  font-size: 12px;
  margin-left: 60px;
  top: -210px;
}
.yaltynyitvatartasok{
  font-size: 12px;
  left: 340px;
  margin:5px;
  position: relative;
  
}
.yaltyelerhetosegek{ 
  font-size: 12px;
  left: 340px;
  margin:5px;
  margin-top: 20px;
  position: relative;
}
.nyitvatartaskiemelese{
  color: #548235;
  font-weight: bold;
}
.nyitvatartaskiemelesezarva{
  color: #E85858;
  font-weight: bold;
}
.nyitvatartaskiemelese2{
  color: black;
}
.yaltyGoogleMap img{
  max-width: 640px;
  max-height: 320px;
  width: 380px;
  height: 190px;
  position: relative;
}


#yaltyMainLogo{
  height: 58px;
  margin-right: 17px;
}
#sloganDivider {
  margin-right: 17px;
}
#yaltySubMainLogo{
  font-size: 150%;
}

.yaltyInfo{
  position: absolute;
  max-width: 550px;
  font-size: 120%;
  line-height: 35px;
  top: 50%;
  margin-left: 230px;
  transform: translate(0, -50%);
}
.yaltyApp{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.aboutTitle{
  color: white;
  font-weight: bold;
  line-height: 200%;
  white-space: pre-line;
}
.yaltyInfo li{
  list-style-position: outside;
  line-height: 40px;
  margin: 0;
  margin-left:20px;
}
ul{
    margin: 0;
    padding: 0;
}
.section{
  position: relative;
  background-color: #00AEEF;
  margin: 0;
  padding: 0;
  height: 100vh;
}
.sectionpubliccoupon{
  overflow: auto;
  position: relative;
  background-color: #00AEEF;
  margin: 0;
  padding: 0;
  height: auto;
  padding-bottom: 100px;
  min-height: 100vh;
  max-height: 500vh;
}
.sectionpubliccoupon::-webkit-scrollbar { 
  display: none; 
}
body::-webkit-scrollbar { 
  display: none; 
}
.section1{
  position: relative;
  background-color:rgb(237,125,49);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: auto;
}
.section2{
  position: relative;
  background-color:rgb(255,255,255);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: auto;
}
.logArea input:-webkit-autofill, .contactArea input:-webkit-autofill, .forgottenArea input:-webkit-autofill{
  box-shadow: 0 0 0 1000px #00AEEF inset !important;
}
.logArea{
  width: 300px;
  margin-left: 180px;
  margin-right: 180px;
}
.forgottenArea{
  background-color: #00AEEF;
  width: 80%;
  padding: 20px;
  overflow: hidden;
  align-items:center;
  justify-content:center;
}
.forgottenArea h3, .forgottenArea p { 
  text-align: center;
}
.contactArea{
  background-color: #00AEEF;
  width: 450px;
  overflow: hidden;
  margin-top: 70px;
  margin-bottom: 70px;
}
.login-button{
    width: 100%;
    min-height: 43px;
    line-height: 24px;
    color: #00AEEF;
    background-color: white;
    text-transform: capitalize;
    border-radius: 40px;
    font-weight: bold;
    font-size: 18px;
    margin: 0!important;
}
.subscription-info-button{
  width: 100px;
  min-height: 20px;
  line-height: 20px;
  color: #ED7D31;
  background-color: white !important;
  text-transform: capitalize;
  border-radius: 5px;
  margin:0!important;
}
.forgotten-button{
  width: 250px;
  min-height: 43px;
  line-height: 24px;
  color: #00AEEF;
  background-color: white !important;
  text-transform: none;
  border-radius: 40px;
  font-weight: bold;
  font-size: 16px;
  display: block;
  margin: auto;
  display: flex;
  justify-content: center;
}
.sign-button{
  width: 40%;
  min-height: 20px;
  line-height: 20px;
  color: #00AEEF;
  background-color: white;
  text-transform: capitalize;
  border-radius: 5px;
  display: block;
  margin: auto;
}
.active{
  color:#4E525C;
  text-transform: uppercase;
  font-weight: bold;
}
h3, md-radio-button{
  color: white;
}
md-content .md-char-counter {
  color: #4E525C;
}
md-radio-button.md-default-theme .md-off, md-radio-button .md-off{
  border-color:#4E525C;
}
.container_upper md-input-container.md-default-theme .md-input, .container_upper md-input-container .md-input, .container_upper md-input-container:not(.md-input-invalid) .md-input-focused input, .signup_upper md-input-container.md-default-theme .md-input, .signup_upper md-input-container .md-input, .signup_upper md-input-container:not(.md-input-invalid) .md-input-focused input{
  border-color: white;
}
.container_upper md-input-container label:not(.md-no-float):not(.md-container-ignore), .container_upper md-input-container label, .container_upper md-input-container .md-placeholder, .signup_upper md-input-container label:not(.md-no-float):not(.md-container-ignore), .signup_upper md-input-container label, .signup_upper md-input-container .md-placeholder{
  color: white;
}
.container_upper md-radio-button.md-default-theme .md-on, .container_upper md-radio-button .md-on, .signup_upper md-radio-button.md-default-theme .md-on, .signup_upper md-radio-button .md-on, .signup_upper md-checkbox.md-checked .md-icon {
  background-color: #4E525C;
}
.container_upper md-radio-button.md-default-theme.md-checked .md-off, .container_upper md-radio-button.md-checked .md-off, .signup_upper md-radio-button.md-default-theme.md-checked .md-off, .signup_upper md-radio-button.md-checked .md-off{
  border-color: #4E525C;
}
.container_upper md-input-container:not(.md-input-invlaid).md-input-focused .md-input, .signup_upper md-input-container:not(.md-input-invlaid).md-input-focused .md-input {
  border-color: #4E525C;
}
.container_upper .md-button.md-default-theme:not([disabled]).md-focused, .container_upper .md-button:not([disabled]).md-focused, .container_upper .md-button.md-default-theme:not([disabled]):hover, .container_upper .md-button:not([disabled]):hover, .signup_upper .md-button.md-default-theme:not([disabled]).md-focused, .signup_upper .md-button:not([disabled]).md-focused, .signup_upper .md-button.md-default-theme:not([disabled]):hover, .signup_upper .md-button:not([disabled]):hover{
  background-color: white;
}


/**/
.container_upper2 .md-virtual-repeat-container ::-webkit-scrollbar{
  display: none;
}
.container_upper2 .md-button.md-default-theme.md-accent[disabled], .container .md-button.md-accent[disabled], .container .md-button.md-default-theme.md-fab[disabled], .container .md-button.md-fab[disabled], .container .md-button.md-default-theme.md-raised[disabled], .container .md-button.md-raised[disabled], .container .md-button.md-default-theme.md-warn[disabled], .container .md-button.md-warn[disabled], .container .md-button.md-default-theme[disabled], .container .md-button[disabled]{
  color:white;
  cursor: not-allowed;
}
.container_upper2 .md-button.md-default-theme[disabled], .container .md-button[disabled]{
  background-color: #DFDFDF;
}
.container_upper2 md-input-container.md-default-theme .md-input, .container md-input-container .md-input {
  border-color:#4E525C;
  color: #4E525C;
}
.container_upper2 md-input-container.md-default-theme .md-placeholder, .container md-input-container .md-placeholder, .container md-input-container.md-default-theme label, .container md-input-container label{
  color: #4E525C;
}
.container_upper2 md-content.md-default-theme, .container md-content {
  color: #4E525C;
  background-color: white;
  overflow: hidden;
}
.container_upper2 md-select.md-default-theme .md-select-value, .container md-select .md-select-value{
  border-bottom-color: #4E525C;
}
.container_upper2 md-select:not([disabled]):focus .md-select-value{
  border-bottom-color: #00AEEF;
}
.container_upper2 [disabled] md-input-container.md-default-theme .md-input, .container [disabled] md-input-container .md-input, .container md-input-container.md-default-theme .md-input[disabled], .container md-input-container .md-input[disabled]{
  color:#000;
  border-color:#4E525C;
}
.container_upper2 md-input-container.md-default-theme.md-input-invalid .md-input, .container md-input-container.md-input-invalid .md-input{
  border-color:#ED7D31;
  color:#ED7D31;
}
.container_upper2 md-input-container.md-default-theme.md-input-invalid .md-char-counter, .container md-input-container.md-input-invalid .md-char-counter, .container md-input-container.md-default-theme.md-input-invalid .md-input-message-animation, .container md-input-container.md-input-invalid .md-input-message-animation, .container md-input-container.md-default-theme.md-input-invalid label, .container md-input-container.md-input-invalid label,.container md-input-container.md-default-theme .md-input-message-animation, .container md-input-container .md-input-message-animation, .container md-input-container.md-default-theme .md-input-messages-animation, .container md-input-container .md-input-messages-animation{
  color:#ED7D31;
}
.container_upper2 md-input-container.md-input-invalid md-select.md-default-theme .md-select-value, .container md-input-container.md-input-invalid md-select .md-select-value{
  color: #ED7D31!important;
  border-bottom-color: #ED7D31!important;
}
.container_upper2 md-select.md-default-theme.ng-invalid.ng-touched .md-select-value, .container md-select.ng-invalid.ng-touched .md-select-value, .container md-select.md-default-theme.ng-invalid.ng-touched .md-select-value, .container md-select.ng-invalid.ng-touched .md-select-value{
  color: #ED7D31!important;
  border-bottom-color: #ED7D31!important;
}
.container_upper2 .md-default-theme .md-datepicker-input-container.md-datepicker-invalid, .md-datepicker-input-container.md-datepicker-invalid, .md-warn .md-default-theme .md-datepicker-input-container.md-datepicker-focused, .md-warn .md-datepicker-input-container.md-datepicker-focused{
  border-bottom-color: #ED7D31!important;
}
.container_upper2 md-select.md-default-theme[disabled] .md-select-icon, 
.container_upper2 md-select[disabled] .md-select-icon, 
.container_upper2 md-select.md-default-theme[disabled] .md-select-value, 
.container_upper2 md-select[disabled] .md-select-value, 
.container_upper2 md-select.md-default-theme[disabled] .md-select-value.md-select-placeholder, 
.container_upper2 md-select[disabled] .md-select-value.md-select-placeholder{
  color:#DFDFDF;
  border-color:#DFDFDF;
}
.container_upper2 md-input-container:not(.md-input-invlaid).md-input-focused .md-input, .container md-input-container:not(.md-input-invlaid).md-input-focused .md-input {
border-color: #00AEEF;
color:#00AEEF;
}
.container_upper2 md-checkbox.md-default-theme.md-checked .md-ink-ripple, .container md-checkbox.md-checked .md-ink-ripple{
  color: #4E525C;
}
.container_upper2 .md-button.md-icon-button{
  margin: 0 -10px;
}
.container_upper2 button.md-datepicker-button.md-icon-button{
  margin: 0 6px;
}
.container_upper2 .md-icon-button + .md-datepicker-input-container{
  margin-left: 0;
}
.container_upper2 .md-datepicker-input-container{
  width: 65%;
}
/*md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label, md-input-container:not(.md-input-invalid).md-input-has-value label{
  color:#00AEEF;
}*/
.container_upper2 md-input-container:not(.md-input-invalid).md-input-focused label{
  color:#00AEEF;
}
.container_upper2 md-checkbox.md-default-theme.md-checked .md-icon, .container md-checkbox.md-checked .md-icon{
  background-color:#4E525C;
}
.container_upper2 .md-checkbox-enabled.md-default-theme[selected] .md-icon, .container .md-checkbox-enabled[selected] .md-icon{
  background-color:#4E525C;
}
.container_upper2 md-card.md-default-theme, .container md-card{
  background-color: rgb(226,240,217);
}
.container_upper2 md-slider.md-default-theme .md-thumb:after, md-slider .md-thumb:after, md-slider.md-default-theme.md-min .md-thumb:after, md-slider.md-min .md-thumb:after {
  border-color: #4E525C; 
  background-color: #4E525C; 
}
.container_upper2 md-slider.md-default-theme .md-track.md-track-fill, md-slider .md-track.md-track-fill{
  background-color: #4E525C; 
}
.container_upper2 md-radio-button.md-default-theme .md-on, md-radio-button .md-on {
  background-color: #4E525C; 
}
.container_upper2 md-radio-button.md-default-theme.md-checked .md-off, md-radio-button.md-checked .md-off {
  border-color: #4E525C;
}
.container_upper2 md-card{
  margin:0;
  margin-top:8px;
}
/**/

.loadingoverlay .spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.loadingoverlay .spinner>div {
  width: 18px;
  height: 18px;
  background-color: #00AEEF;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.loadingoverlay .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loadingoverlay .spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
      -webkit-transform: scale(0)
  }
  40% {
      -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
      -webkit-transform: scale(0);
      transform: scale(0);
  }
  40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
  }
}


.upper_container {
  height:100vh;
  width: 100%;
  display:flex;
  align-items:center;
  justify-content:center;
}
.lower_container a{
  color: white;
  text-decoration: none;
}
.lower_container{
  height:10vh;
  width: 100%;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size: 90%;
  color: #4E525C;
}
.forgottenPass{
  text-align:center;
  margin-top:20px;
  color: #4E525C;
  font-size: 90%;
}
.forgottenPass a{
  color: #4E525C;
  text-decoration: none;
}
.signupSuccess{
  font-size:90%;
  margin-top: 230px;
  margin-left: 100px;
  margin-right: 100px;
}
.successText{
  margin: 5vh;
  text-align:center;
  width: 550px;;
  font-size: 90%;
}
.successText h4{
  color: white;
  margin-bottom:10vh;
}
footer{
  background-color:#4E525C;
  color: white;
  margin: 0;
  padding: 0;
}
.footer table{
  padding: 30px;
  width: 80%;
}
.footer table td{
  cursor: pointer;
  outline: none;
  border: 0;
}
.footer #copyright::before {
    content: '\00A9 ';
}



#responsiveimage{
  max-width: 100%;
  height: auto;
}

.hamburgermenu{
  display: none;
}
.hamburgermenuclickable{
  display: none;
}
.nyomtatasnezet{
  display: block;
}
.normalmenu {
  display: block;  
}
.noviewmobile{
  display: block;
}
.viewmobile{
  display: none;
}
.yaltyfooterlogo{
  display: block;
  font-family: 'Verdana-Bold';
}
.yalty-footer-logo-desktop {
    bottom: 44px;
    right: 73px;
    position: absolute;
    height: 39px;
}

.categoritablet{
  display: none;
}
.categorinormal{
  display: block;
}
#partnertittledesktop{
  display: block;
  margin: 0;
  margin-right: 350px;
}
#partnertittlenodesktop{
  display: none;
}
#mobilvalasztobox{
  width: 180px;
 }
 #mobilvalasztobox1{
  width: 180px;
  margin-left: 0px;
 }
 @media only screen and (min-width:981px){
  .yaltyGoogleMap {
    width: 380px;
    height: 190px;
  }
 }
 @media only screen and (max-width:1000px) {
  .notabletmenu{
    display: none;
  }
}
/*Tablet*/
@media only screen and (max-width:980px) {
  #mobilvalasztobox{
    width: 300px;
  }
  #mobilvalasztobox1{
    width: 300px;
    margin-left: 130px;
  }
  .yaltyDiscount{
    right:  82.5%;
  }
  #responsiveimagemobile{
    margin-left:40%;
  }
  #responsiveimagemobiletext{
    margin-left: 10px;
  }
  #responsiveimagemobile2{
    margin-left:40%;
  }

  .aboutTitle{
    white-space: unset;
  }
  #yaltyAppTText{
    margin-left: -40%;
    padding-left: 10px;
    margin-right: 10px;
  }
  #yaltyAppTText2{
    margin-left: -65%;
    padding-left: 15px;
    margin-right: 10px;
  }
  .noviewmobile{
    display: none;
  }
  .viewmobile{
    display: block;
    margin-left: 100px;
  }
  .normalmenu {
    display: block;  
  }
  .hamburgermenu{
    display: block;
    background-color: transparent;
    z-index: -1;
    margin-top: -30px;
  }
  .hamburgermenuclickable{
    display: block;
    width: 35px;
    height: 30px;
    background-color: transparent;
    z-index: 99999999999999999;
  }
  .sidebarIconToggle{
    z-index: -1;
  }
  .yaltyNyomtatasButtonDetails{
    display: none;  
  
  }
  .yaltyNyomtatasButton2Details{
    display: none;  
  
  }
  .yaltyNyomtatasButton{
    display: none;
  }
  .yaltyNyomtatasBox{
    display: none;

  }
  .nyomtatasnezet{
    display: none;
  }
  .notabletmenu{
    display: none;
  }
  .yaltyStyle{
    margin-left: 100px;
    margin-top: -4px;
  }

.yaltyDiscountSearch{

  width: 640px;

}
.yaltyDiscountSearch input[type=text] {
  
  width: 610px;

}


.yaltyDiscountContainer{
  grid-template-columns: auto, auto;

}
.yaltyShowCouponDetails{
  width: 96vw;
  margin-left: -48vw;
  height: 50vw;
  margin-top: -22vw;
}
.yaltyShowCouponDetailsSzolgaltatoLogo{
  width: 15vw;
  height: 6vw;
  margin-left: -48vw;
  margin-top: -29vw;

}
.yaltyShowCouponDetailsX{
  width: 15vw;
  /*margin-top: -36.5vw;*/
  margin-top: -27vw;
  height: 6vw;
  margin-left: 18vw;
}


.yaltyShowCouponDetailsSzolgaltatoLogo img{

  width: none;
  height: 100%;

}

.reszletesnezetbox1{
  margin-left: 0px;
  margin-top: 0px;
  position: static;
}
.reszletesnezetbox2{
  margin-left: 0px;
  margin-top: 0px;
  position: static;
  width: 100%;
}
.yaltyShowCouponDetailsleiras{
  position: static;
}

.yaltyDiscountDetailsOtherCompConteiner{
  position: static;
  max-height: 680px;
}
.yaltyDiscountDetailsOtherCompInfo{
  position: static;
  margin-top: 10px;
}
.yaltyNyitvaZarva{
  position: static;
  margin-bottom: 10px;
}
.yaltyTovabbiInfok{
  position: static;
  width: 25vw;
  margin-bottom: 40px;
}
.yaltyGoogleMap img{
  position: static;
  margin-top: 40px;
}
.yaltyTovabbiak2{
  position: static;
  margin-left: 0px;
}
.yaltynyitvatartasok{
  position: static;
}
.yaltyelerhetosegek{
  position: static;
}
.mobileBannerHU .mobileBannerClickable, .mobileBannerEN .mobileBannerClickable{
  background-size: contain;
  width: 100%;
  height: 24vw;
}
.mobileBannerHU .mobileBannerClickable #closeMobileBanner, .mobileBannerEN .mobileBannerClickable #closeMobileBanner {
  width: 7vw;
  height: 24vw;
  outline: none;
}
.mobileBannerHU .mobileBannerClickable #navigateToDownload, .mobileBannerEN .mobileBannerClickable #navigateToDownload {
  width: 95vw;
  height: 24vw;
  margin-left: 7vw;
  margin-top: -24vw;
  outline: none;
}
.categoritablet{
  display: block;
}
.categorinormal{
  display: none;
}
.yaltyDiscountCategoryBox{
  right: 16%;
  top: 18%;
  margin-left: auto;
  margin-right: auto;
  min-height: 70px;
  width: 650px;
  height: 70px;
}
.yaltyDiscount{
  top: 220px;
  right: 82.5%;
  margin-left: auto;
  margin-right: auto;
}
.yaltyDiscountCategoryBoxVarosom{
  margin-left: 5px;
  text-align: unset;
}
.categoritablet{
  position: relative;
  top: -65px;
  left: 205px;
}
}

/*Mobil*/
@media only screen and (max-width:700px) {
 /* .sidebarIconToggle{
    top: -30px;

  }*/
  .normalmenu {
    display: none;  
  }
  .hamburgermenu{
    display: block;
    background-color: transparent;
    z-index: -1;
    margin-top: -30px;
  }
  .hamburgermenuclickable{
    display: block;
    width: 35px;
    height: 30px;
    background-color: transparent;
    z-index: 99999999999999999;
  }
  .sidebarIconToggle{
    z-index: -1;
  }
  .notabletmenu{
    display: none;
  }
  .nomobilemenu{
    display: none;
  }

  .yaltyNyomtatasButtonDetails{
    display: none;  
  
  }
  .yaltyNyomtatasButton2Details{
    display: none;  
  
  }
  .yaltyNyomtatasButton{
    display: none;
  }
  .yaltyNyomtatasBox{
    display: none;

  }
  .nyomtatasnezet{
    display: none;
  }
  .yaltyDiscountSearch{

    width: 47vw;
  
  }
  .yaltyDiscountSearch input[type=text] {
    width: 40vw;
  }

}

@media only screen and (max-width:1222px) {
  .sloganStyle{
    display: none;
  }
}

@import url('https://fonts.googleapis.com/css?family=Varela+Round');


.translationFieldContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.translationField {
  width: 40%;
  min-height: 96px;
  padding: 0px 20px 0px 20px;
  margin-top: 20px;
}

.translationFieldOnLeftWithCopyButton {
  align-self: auto;
}

.translationFieldOnLeftWithMultipleLines {
  align-self: baseline;
}

.translationFieldOnRightWithCopyOrMultipleLines {
  align-self: baseline;
}

.translationArrow {
  min-width: unset;
  padding: 0;
  align-self: center;
}

.translationFieldContainer .translationArrowWithCopyButton  {
  margin-top: 42px;
  margin-bottom: 83px;
}

.translationFieldContainer .translationArrow img {
  width: 30px;
  outline: none;
}

.translationFieldOnLeftWithCopyButton .translationCopyButtonContainer .translationCopyButton,
.translationFieldOnLeftWithMultipleLines .translationCopyButtonContainer .translationCopyButton {
  background-color: #00AEEF;
  color: white;
  text-transform: unset;
  width: 200px;
  text-decoration: none;
  margin: 0;
  height: 30px;
}

.translationCopyButtonContainer {
  margin-top: -20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.translationFieldContainer .tooltip .yaltytiptextbottom {
  left: -25px;
  width: 300px;
  top: 30px;
  margin-top: 0
}

.translationFieldContainer .tooltip .yaltytiptextbottom::after {
  left: 30px;
}


md-switch.md-checked .md-thumb {
  background-color: #00AEEF;
}

md-switch.md-checked .md-bar {
  background-color: #00AEEF; 
}

#publicPages .main {
    margin: 0 auto;
    display: block;
    height: 100%;
    margin-top: 60px;
}
.mainInner{
    display: table;
    height: 100%;
    width: 100%;
    text-align: center;
}
.mainInner div{
    display:table-cell;
    vertical-align: middle;
    font-size: 3em;
    font-weight: bold;
    letter-spacing: 1.25px;
}
#sidebarMenu {
    height: 100%;
    position: fixed;
    left: 0;
    width: auto;
    padding-right: 10px;
    margin-top: 60px;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    background-color: #000000; 
    opacity: 0.8;
    overflow-y: auto;


}
.sidebarMenuInner{
    margin:0;
    padding:0;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li{
    list-style: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li span{
    display: block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.50);
}
.sidebarMenuInner li a{
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}
input[type="checkbox"]:checked ~ #sidebarMenu {
    transform: translateX(0);
}

input[type=checkbox] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
    transform: scale(6);
}
.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    /*top: 20px;
    left: 5px;*/
    height: 25px;
    width: 25px;
    margin-top: 10px;
    margin-left: 10px;
}

.spinnerr {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
    
}
.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}
.diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}
.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}

/* Prereg email-send-type-container styles */
.container .email-send-type-container.md-input-focused .md-text,
.container .email-send-type-container .md-select-icon,  
.container .email-send-type-container .md-select-value, 
.container .email-send-type-container .md-select-value.md-select-placeholder,
.container .email-send-type-container md-select:not([disabled]):focus .md-select-value {
    color: #fff;
    border-color: #fff;
}

.responsivecenter {
  overflow: hidden;
}

.responsivecenter > div {
  position: relative;
  margin: 0 auto;
  max-width: 100px;
}

.responsivecenter > div:before,
.responsivecenter > div:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 999999px;
}

.responsivecenter > div:before {
  right: 100%;
}

.responsivecenter > div:after {
  left: 100%;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

#publicPages * {
  box-sizing: border-box;
}
.row::after {
  content: "";
  clear: both;
  display: table;
}
[class*="col-"] {
  float: left;
  padding: 15px;
}

.outer {
  position: relative;
  width: 100%;
}

.outer.r4x3 {
  padding-top: 75%; 
}
.outer .inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.inner::after {
  content: attr(data-size);
  position: absolute;
  left:0;
  top: -1em;
}

.little-box {
  float: left;
  width: 100%;
  height: 100%;
}

.transaction-status {
    width: 370px;
    font-weight: bold;
    margin-left: 20px;
    text-align: center;
    height: 30px;
    line-height: 30px;
}

.transaction-successful {
    background-color: #81cf4d;
    
}

.transaction-unsuccesful {
    background-color: #E85858;
}

.invoicing-column {
    border-left:  solid #F2F2F2;
    min-height: 500px;
    min-width: 380px;
}

.invoicing-column.align-center {
    text-align: center;
}

.invoicing-column md-radio-button {
    color: #4E525C;
    width: 180px;
    margin: 10px auto;
}

.invoicing-column .display-pdf-name {
    font-size: 15px;
}

.invoicing-container {
    display: flex;
    margin-top: 50px;
}

.invoicing-info {
    display: flex;
    width: 100%;
    align-items: center;
}

.invoicing-column-title {
    text-align: center;
    width: 100%;
    font-weight: bold;
    margin-bottom: 30px;
}

.invoicing-label {
    text-align: right;
    width: 40%;
    padding: 5px;
}

.invoicing-value {
    text-align: center;
    width: 60%;
    padding: 5px;
}

.invoicing-value-important {
    font-weight: bold;
}

.administration-window_notes {
    height: 256px; 
    width: 956px; 
    resize: none;
    padding-bottom: 67px;
    box-sizing: border-box;
}



@media only screen and (max-width:860px) {
  #responsiveimagemobile{
    margin-left:38%;
  }
  #responsiveimagemobile2{
    margin-left:38%;
  }
  #yaltyAppTText{
    margin-left: -38%;
    padding-left: 10px;
    margin-right: 10px;
  }
  #yaltyAppTText2{
    margin-left: -58%;
    padding-left: 15px;
    margin-right: 10px;
  }
#kapcsolatilaptitle{
  top: -40%;
  left: 22%;
}

  #partnertittledesktop{
    display: none;
    margin: 0;
    margin-right: 350px;
  }
  #partnertittlenodesktop{
    display: block;
  }
  .currentPageTitle {
    font-size:160%;
  }

  .yaltyDiscount{

    right:  87%;

  }
  .yaltyDiscountCategoryBox {
    right: 12%;
  }
}

  @media only screen and (max-width:990px) {
    /*#kapcsolatfooter{
      padding-left: 162px;
    }*/
  
  
}

@media only screen and (max-width:835px) {
  .yaltyDiscount{

    right:  89%;
    
  }
  .yaltyDiscountCategoryBox {
    right: 11.5%;
  }
  /*#kapcsolatfooterI{
    padding-left: 154px;
  }
  #kapcsolatfooter{
    padding-left: 0px;
  }*/

}


@media only screen and (max-width:800px) {
  #responsiveimagemobile{
    margin-left:37%;
  }
  #responsiveimagemobile2{
    margin-left:37%;
  }
  #yaltyAppTText{
    margin-left: -37%;
    padding-left: 10px;
    margin-right: 10px;
  }
  #yaltyAppTText2{
    margin-left: -56%;
    padding-left: 15px;
    margin-right: 10px;
  }
  /* .sidebarIconToggle{
     top: -30px;
 
   }*/
   .yaltyDiscountCategoryBox{
     right: 9%;
     top: 18%;
     margin-left: auto;
     margin-right: auto;
 
   }
   .yaltyDiscount{
     top: 220px;
     right: 90%;
     margin-left: auto;
     margin-right: auto;
   }
   .yaltyDiscountSearch{
     width: 640px;
   }
   .yaltyDiscountSearch input[type=text] {
     width: 600px;
   }
 }
 @media only screen and (max-width:768px) {
 
   .viewmobile{
     margin-left: 50px;
   }
   .yaltyDiscountCategoryBox{
     right: 7%;
     top: 18%;
     margin-left: auto;
     margin-right: auto;
 
   }
   .yaltyDiscount{
     top: 220px;
     right: 91.5%;
     margin-left: auto;
     margin-right: auto;
   }
 }
 @media only screen and (max-width:650px) {
  .yaltyShowCouponDetails{
    width: 96vw;
    margin-left: -48vw;
    height: 78vw;
    margin-top: -22vw;
  }
  .yaltyShowCouponDetailsSzolgaltatoLogo{
    width: 15vw;
    height: 6vw;
    margin-left: -48vw;
    margin-top: -41vw;
  
  }
  .yaltyShowCouponDetailsX{
    width: 15vw;
    /*margin-top: -36.5vw;*/
    margin-top: -39vw;
    height: 6vw;
    margin-left: 18vw;
  }
  #mobilvalasztobox{
    width: 280px;
   }
   #mobilvalasztobox1{
    width: 280px;
    margin-left: 0px;

   }
  #responsiveimagemobile{
    margin-left: 35%;
  }
  #responsiveimagemobile2{
    margin-left:35%;
  }
  #yaltyAppTText{
    margin-left: -35%;
    padding-left: 10px;
    margin-right: 10px;
  }
  #yaltyAppTText2{
    margin-left: -50%;
    padding-left: 15px;
    margin-right: 10px;
  }
  #kapcsolatilaptitle{
    left: 28%;
  }
  .contactArea{
    width: 400px;
  }
 

   .yaltyDiscountSearch{
     width: 300px;
   }
   .yaltyDiscountSearch input[type=text] {
     width: 260px; 
   }
 
   .yaltyDiscountContainer {
    grid-template-columns: auto;
}
   .yaltyDiscountCategoryBox{
    width: 300px;
    height: 130px;
    left: 12%;
   }

  .categoritablet {
    left: 5px;
    top: 0px;
  }
   .yaltyDiscount{
     top: 220px;
     right: 73%;
     margin-left: auto;
     margin-right: auto;
   }
 }

 @media only screen and (max-width:960px) {
  .contactArea {
    margin-top: 10px;
  }
 }

 @media only screen and (max-width:1150px) and (min-width:852px) {
  .partnerHomeLargeScreen {
    display: none;
  }
  .partnerHomeSmallScreen {
    display: none;
  }
  .partnerHomeSectionTitle {
    font-weight: bold;
    align-self: center;
    text-align: center;
    font-size: 20px; 
    margin-top: 5px; 
    margin-bottom: 5px;
  }
  .partnerHomeSectionText {
    margin: 0; 
    font-size: 14px;
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
 }

 @media only screen and (min-width:1151px) {
  .partnerHomeMediumScreen {
    display: none;
  }
  .partnerHomeSmallScreen {
    display: none;
  }
  .partnerHomeSectionTitle {
    font-weight: bold; 
    font-size: 20px;
    align-self: center;
    text-align: center;
    margin-top: 5px; 
    margin-bottom: 5px;
  }
  .partnerHomeSectionText {
    margin: 0; 
    font-size: 14px;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
 }

 @media only screen and (max-width:851px) {

  .partnerHomeText3 {
    font-size: 22px;
  }

  .partnerHomeText4 {
    font-size: 18px;
    margin-left: auto; 
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 790px;
  }

  .partnerHomeLargeScreen {
    display: none;
  }
  .partnerHomeMediumScreen {
    display: none;
  }
  .partnerHomeSectionTitle {
    font-weight: bold;
    font-size: 17px;
    margin-top: 5px; 
    margin-bottom: 5px;
    align-self: center;
    text-align: center;
  }
  .partnerHomeSectionText {
    margin: 0; 
    font-size: 13px;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
 }

 @media only screen and (max-width:550px) {

  .partnerHomeText {
    font-size: 18px;
  }

  .partnerHomeText2 {
    font-size: 22px;
  }

  .partnerHomeText3 {
    font-size: 22px;
  }

  .partnerHomeText4 {
    font-size: 18px;
    margin-left: auto; 
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 790px;
  }

  .logArea{
    width: 300px;
    margin-left: 50px;
    margin-right: 50px;
  }
 
   .viewmobile{
     margin-left: 0px;
   }
   .yaltyDiscountCategoryBox{
     right: 7%;
     top: 18%;
     margin-left: auto;
     margin-right: auto;
 
   }
   .yaltyDiscount{
 /*    top: 620px;*/
     right: 77%;
     margin-left: auto;
     margin-right: auto;
   }
 }
 
 @media only screen and (max-width:500px) {

  #yaltyfooterlogo{
    display: none;
    font-family: 'Verdana-Bold';
  }
  #kapcsolatilaptitle{
    left: 34%;
  }
  .contactArea{
    width: 350px;
  }
 
   #yaltyMainLogo{
       height: 46px;
       margin-right: 14px;
   }
   #sloganDivider {
    margin-right: 14px;
  }
   #yaltySubMainLogo{
     font-size: 120%;
   } 

   .yaltyShowCouponDetailskedvezmenyszazalek {
     font-size: 20px;
     }
     
     .yaltyShowCouponDetailskedvezmenyar {
     font-size: 20px;
     }
     
     .yaltyShowCouponDetailskedvezmenyujar {
     font-size: 20px;
     }
     
     yaltyShowCouponDetailskedvezmenyregiar {
     font-size: 12px;
     }
     #detailskedvezmenytext {
       font-size: 15px;
     }
   
     #detailskedvezmenytext img{
       width: 10px;
       height: 10px;
     }
 
 
 .yaltyShowCouponDetailskedvezmenyregiar{
   font-size: 3.5vw;
 
 }
 
 
 .yaltyShowCouponDetailsszolgaltato{
   font-size: 2.8vw;
 
 }
 .yaltyShowCouponDetailscim{
   font-size: 2.8vw;
 }
 
   .yaltyShowCouponDetails img{
     width: 56vw;
     height: 34vw;
   }
   .yaltyShowCouponDetailskedvezmenytext img{
     width: 20px;
     height: 20px;
   }
 
   .reszletesnezetbox2{
     font-size: 3vw;
     width: 100%;
 
   }
   .reszletesnezetbox3{
     padding-top: 0px;
   }
   
   .yaltyDiscountCategoryBox{
     right: 11%;
     top: 24%;
     margin-left: auto;
     margin-right: auto;
 
   }
   .yaltyDiscount{
     top: 220px;
     right: 80%;
     margin-left: auto;
     margin-right: auto;
   }
   .yaltyShowCouponDetails{
    width: 96vw;
    margin-left: -48vw;
    height: 80vw;
    margin-top: -34vw;
  }
  .yaltyShowCouponDetailsSzolgaltatoLogo{
    width: 15vw;
    height: 6vw;
    margin-left: -48vw;
    margin-top: -42vw;
  
  }
  .yaltyShowCouponDetailsX{
    width: 15vw;
    /*margin-top: -36.5vw;*/
    margin-top: -41vw;
    height: 6vw;
    margin-left: 18vw;
  }
 }
 @media only screen and (max-width:430px) {
  .logArea{
    width: 300px;
    margin-left: 50px;
    margin-right: 50px;
  }
  #responsiveimagemobile{
    margin-left: 27%;
  }
  #responsiveimagemobile2{
    margin-left:26%;
  }
  #yaltyAppTText{
    margin-left: -26%;
    padding-left: 10px;
    margin-right: 10px;
  }
  #yaltyAppTText2{
    margin-left: -30%;
    padding-left: 15px;
    margin-right: 10px;
  }
 
   #yaltyMainLogo{
       height: 41px;
       margin-right: 12px;
   }
   #sloganDivider {
    margin-right: 12px;
  }
   #yaltySubMainLogo{
     font-size: 105%;
   }
   .categoritablet{
     left: 5px;
     top: 0px;
   }
   .yaltyDiscountCategoryBox{
     width: 300px;
     height: 130px;
     left: 13%;
    }

   .yaltyDiscount {
   margin-top: 30px;
   right: 85%;
   }
   #mobilvalasztobox{
    width: 280px;
   }
   #mobilvalasztobox1{
    width: 280px;
    margin-left: 0px;

   }
 }
 @media only screen and (max-width:375px) {
  #responsiveimagemobile {
    margin-left: 23%;
}
#responsiveimagemobile2{
  margin-left:23%;
}
#yaltyAppTText{
  margin-left: -23%;
  padding-left: 10px;
  margin-right: 10px;
}
#yaltyAppTText2{
  margin-left: -25%;
  padding-left: 15px;
  margin-right: 10px;
}
  #kapcsolatilaptitle{
    left: 46%;
  }
   .logArea{
     width: 300px;
     margin-left: 50px;
     margin-right: 50px;
   }
   .yaltyShowCouponDetailskedvezmenyszazalek {
     font-size: 15px;
     }
     
     .yaltyShowCouponDetailskedvezmenyar {
     font-size: 15px;
     }
     
     .yaltyShowCouponDetailskedvezmenyujar {
     font-size: 15px;
     }
     
     yaltyShowCouponDetailskedvezmenyregiar {
     font-size: 9px;
     }
     #detailskedvezmenytext {
       font-size: 10px;
     }
 
 
   #yaltyMainLogo{
       height: 35px;
       margin-right: 10px;
   }
   #sloganDivider {
    margin-right: 10px;
  }
   #yaltySubMainLogo{
     font-size: 90%;
   }
   .yaltyDiscountCategoryBox{
     left: 11%;
     top: 24%;
     margin-left: auto;
     margin-right: auto;
 
 
   }
   .yaltyDiscount{
     top: 220px;
     right: 90%;
     margin-left: auto;
     margin-right: auto;
   }
   .yaltyfooterlogo{
     display: none;
     font-family: 'Verdana-Bold';
   }

 }
 @media only screen and (max-width:335px) {
  #responsiveimagemobile {
    margin-left: 18%;
}
#responsiveimagemobile2{
  margin-left:18%;
}
#yaltyAppTText{
  margin-left: -18%;
  padding-left: 10px;
  margin-right: 10px;
}
#yaltyAppTText2{
  margin-left: -15%;
  padding-left: 15px;
  margin-right: 10px;
}
  #kapcsolatilaptitle{
    left: 55%;
  }
  .contactArea{
    width: 240px;
  }
   .viewmobile{
     display: block;
     margin-left: -20px;
   }
   .yaltyDiscountCategoryBox{
     left: 5%;
     top: 24%;
     margin-left: auto;
     margin-right: auto;
   }
   .yaltyDiscount{
     /*top: 620px;*/
     right: 95%;
     margin-left: auto;
     margin-right: auto;
   }

 }

@media only screen and (max-width:780px) {
  /*#Adatvedelem{
    padding-left: 162px;
  }
  #kapcsolatfooterI{
    padding-left: 0px;
  }
  #kapcsolatfooter{
    padding-left: 0px;
  }*/

}
@media only screen and (max-width:710px) {
  
  #kapcsolatfooter{
    padding-left: 162px;
  }

}
@media only screen and (max-width:608px) {

  #Adatvedelem{
    padding-left: 162px;
  }
  #kapcsolatfooterI{
    padding-left: 0px;
  }
  #kapcsolatfooter{
    padding-left: 0px;
  }
}

@media only screen and (max-width:583px) {
  
/*
  #kapcsolatfooterI{
    display: none;
  }
  #AdatvedelemI{
    display: none;
  }*/
}/*
@media only screen and (max-width:554px) {
  
  #kapcsolatfooter{
    padding-left: 135px;
  }

}
@media only screen and (max-width:530px) {
  #copyright{
    padding-left: 145px;
  }
  #Joginyilatkozat{
    padding-left: 145px;
  }

}
@media only screen and (max-width:501px) {
  #Adatvedelem{
    padding-left: 145px;
  }
 

}
*/
@media only screen and (max-width:530px) {
  #kapcsolatfooterI{
    display: none;
  }
  #AdatvedelemI{
    display: none;
  }
  #kapcsolatfooter{
    /*padding-left: 162px;*/
    padding-left: 25px;
  }
  #copyright{
    /*padding-left: 145px;*/
    padding-left: 25px;
  }
  #Adatvedelem{
    padding-left:50px;
  }
  #Joginyilatkozat{
    /*padding-left: 145px;*/
  }

}
@media only screen and (max-width:400px){
  #footercopyrightandmenu{
    margin-left: -20px;
  }
}
@media only screen and (max-width:340px){
  #footercopyrightandmenu{
    margin-left: -40px;
  }
}